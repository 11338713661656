import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AffectationService {
  url: any;

  Spring_serveur: string = 'api/actions/';
  constructor(private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur;
  }

  public assignm(formData: any, id_Doc: any, sc: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + sc + `?` + sc + `ID=${id_Doc}`, formData, {
      headers: headers1,
    });
  }

  public reassign(formData: any, id_action: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(this.url + `${id_action}`, formData, {
      headers: headers1,
    });
  }
}
