import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PremissionService } from 'src/Services/Premission/premission.service';
import { UserService } from 'src/Services/User/user.service';

import Langue from './../../../../../assets/langue.json';

@Component({
  selector: 'app-page-accueil-customs',
  templateUrl: './page-accueil.component.html',
  styleUrls: ['./page-accueil.component.css'],
})
export class PageAccueilComponent implements OnInit {
  selectedIndex: any = 0;
  index: number;
  Custom_etat: boolean = false;
  Tecnhical_etat: boolean = false;
  Fde_etat: boolean = false;

  @Input() profil: string;
  langue: any;
  direction: any;
  languef: any;
  languea: any;
  langue_list: any[] = Langue;

  constructor(
    private PremisionApi: PremissionService,
    private userService: UserService,
    private router: Router
  ) {
   // this.selectedIndex = localStorage.getItem('redirecturFDE');

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].header;
      this.languea = this.langue_list[0]['AR'].CD;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].header;
    }
    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].header;
    }
  }

  Team_Manager: boolean = false;
  ngOnInit(): void {
    if (localStorage.getItem('redirecturlC') != undefined) {
      this.selectedIndex = localStorage.getItem('redirecturlC');
    }

    this.Custom_etat = this.PremisionApi.GetCustomsDeclaration();
    this.Tecnhical_etat = this.PremisionApi.GetTechnicalControl();
    this.Team_Manager = this.userService.ValidRole('Team manager');

    let etat = !this.Tecnhical_etat && !this.Custom_etat && !this.Fde_etat
    if (etat) {
      //  this.router.navigateByUrl('Error/notfound')
    }
  }

  changesc(event: any) {
    localStorage.setItem('redirecturlC', event.index);
  }
}
