import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageUserService {
  url: any;
  urlbil: any;
  urlfde: any

  urldf: any
  urlTransfert: any

  Spring_serveur: string = 'api/';
  constructor(private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur;
    this.urlbil = environment.UrlBil + 'billoflading/api/';
    this.urlfde = environment.UrlGetway;
    this.urldf = environment.UrlGetway + "dhamenfinance/api/";
    this.urlTransfert = environment.UrlGetway

  }
  public Get(id: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(`${this.url + 'message-usersbyship/' + id}`, {
      headers: headers1,
    });
  }

  public GetCargoMessage(id: any) {
    return this.http.get<any>(`${this.url + 'message-users-by-cargo/' + id}`);
  }

  public GetShipdocking(id: any) {
    return this.http.get<any>(`${this.url + 'message-users-by-docking/' + id}`);
  }
  public GetCustom(id: any) {
    return this.http.get<any>(`${this.url + 'message-users-by-customs/' + id}`);
  }

  public Gettecknical(id: any) {
    return this.http.get<any>(
      `${this.url + 'message-users-by-technical/' + id}`
    );
  }

  public GetBilloflading(id: any) {
    return this.http.get<any>(`${this.urlbil + 'message-users/by-bol/' + id}`);
  }

  public GetFoprodexMessage(id: any) {
    return this.http.get<any>(`${this.url + 'message-users/by-fop/' + id}`);
  }
  public GetFdeMessage(id: any) {
    return this.http.get<any>(`${this.urlfde + 'fde/api/message-users/by-fde/' + id}`);
  }

  public GetCx5Message(id: any) {
    return this.http.get<any>(`${this.urlfde + 'cx5/api/message-users/by-cx5/' + id}`);
  }
  public GetDecotuMessage(id: any) {
    return this.http.get<any>(`${this.urlfde + 'decotu/api/message-users/by-cx5/' + id}`);
  }

  public GetdhamenExportMessage(id: any) {
    return this.http.get<any>(`${this.urldf + 'message-users/by-dhf/' + id}`);
  }

  public GetTransferMessage(id: any) {
    console.log(id)
    return this.http.get<any>(`${this.urlTransfert + 'transfert/api/message-users/transf/' + id}`);
  }
}
