<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css"
/>
<div *ngIf="!skeleton">
  <h2 class="titre-page">{{ languef.customs }}</h2>
</div>

<!-- <div class="div_action">
  <mat-icon class="icon_action">widgets</mat-icon>
</div> -->

<div class="row" *ngIf="!skeleton">
  <div class="col-lg-6">
    <div class="card1">
      <div style="width: 100%; text-align: center">
        <span class="titre-card">{{ languef.cd }}</span>
      </div>
      <div class="row mobile_card">
        <div class="col-lg-6">
          <div class="sous_card1">
            <div>
              <span class="titre_sous_card"> {{ languef.op }}</span>
            </div>
            <div>
              <span class="titre_sous_card">
                {{ cd_save }}
                <b style="font-size: 11px"> ({{ cd_save_moy }}%) </b></span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card2">
            <div>
              <span class="titre_sous_card"> {{ languef.fl }} </span>
            </div>
            <div>
              <span class="titre_sous_card"
                >{{ cd_refuse
                }}<b style="font-size: 11px"> ({{ cd_refuse_moy }}%) </b>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card3">
            <div>
              <span class="titre_sous_card"> {{ languef.fp }} </span>
            </div>
            <div>
              <span class="titre_sous_card">
                {{ cd_forconf }}
                <b style="font-size: 11px"> ({{ cd_forconf_moy }}%) </b></span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card4">
            <div>
              <span class="titre_sous_card"> {{ languef.cc }} </span>
            </div>
            <div>
              <span class="titre_sous_card">
                {{ cd_accept }}
                <b style="font-size: 11px"> ({{ cd_accept_moy }}%) </b></span
              >
            </div>
          </div>
        </div>
      </div>

      <div
        style="display: flex; padding-top: 7%"
        [ngStyle]="{ direction: direction }"
      >
        <div style="display: flex">
          <div style="width: 130px">
            <span class="total">{{ languef.tf }} : </span>
          </div>
          <div>
            <span class="res">{{ cd_total }} </span>
          </div>
        </div>
        <div class="more">
          <button
            mat-button
            class="btn btn-light shadow-lg"
            style="display: flex"
            class="button_show"
            (click)="navigation()"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <div>
              <label class="document"> {{ languef.sm }} </label>
            </div>
            <div>
              <mat-icon
                *ngIf="langue == 'EN' || langue == 'FR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_right</mat-icon
              >
              <mat-icon
                *ngIf="langue == 'AR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_left</mat-icon
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6">
    <div class="card1">
      <div style="width: 100%; text-align: center">
        <span class="titre-card"> {{ languef.tc }}</span>
      </div>
      <div class="row mobile_card">
        <div class="col-lg-6">
          <div class="sous_card1">
            <div>
              <span class="titre_sous_card"> {{ languef.op }}</span>
            </div>
            <div style="padding-top: 6%">
              <span class="titre_sous_card"
                >{{ ts_save }}
                <b style="font-size: 11px"> ({{ ts_save_moy }}%) </b></span
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="sous_card2">
            <div>
              <span class="titre_sous_card"> {{ languef.fl }} </span>
            </div>
            <div style="padding-top: 3%">
              <span class="titre_sous_card">
                {{ ts_refuse }}
                <b style="font-size: 11px"> ({{ ts_refuse_moy }}%) </b>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card3">
            <div>
              <span class="titre_sous_card"> {{ languef.fp }} </span>
            </div>
            <div style="padding-top: 3%">
              <span class="titre_sous_card">
                {{ ts_forconf }}
                <b style="font-size: 11px"> ({{ ts_forconf_moy }}%) </b></span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card4">
            <div>
              <span class="titre_sous_card"> {{ languef.cc }} </span>
            </div>
            <div style="padding-top: 3%">
              <span class="titre_sous_card">
                {{ ts_accept
                }}<b style="font-size: 11px"> ({{ ts_accept_moy }}%) </b>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        style="display: flex; padding-top: 6%"
        [ngStyle]="{ direction: direction }"
      >
        <div style="display: flex">
          <div style="width: 130px">
            <span class="total">{{ languef.tf }} : </span>
          </div>
          <div>
            <span class="res">{{ ts_total }} </span>
          </div>
        </div>
        <div class="more">
          <button
            mat-button
            class="btn btn-light shadow-lg"
            style="display: flex"
            class="button_show"
            (click)="navigationTc()"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <div>
              <label class="document"> {{ languef.sm }} </label>
            </div>
            <div>
              <mat-icon
                *ngIf="langue == 'EN' || langue == 'FR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_right</mat-icon
              >
              <mat-icon
                *ngIf="langue == 'AR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_left</mat-icon
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="skeleton">
  <div style="text-align: center">
    <ngx-skeleton-loader
      [theme]="{
        width: '250px',
        'border-radius': '15px',
        height: '45px',
        'margin-bottom': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="card1">
        <div class="row">
          <div class="col-lg-6">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card2f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-lg-6 sous_card3f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card4f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card1">
        <div class="row">
          <div class="col-lg-6">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card2f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-lg-6 sous_card3f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card4f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card1">
        <div class="row">
          <div class="col-lg-6">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card2f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-lg-6 sous_card3f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card4f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card1">
        <div class="row">
          <div class="col-lg-6">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card2f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-lg-6 sous_card3f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="col-lg-6 sous_card4f">
            <ngx-skeleton-loader
              [theme]="{
                width: '160px',
                height: '50px',
                'margin-top': '40.2px',
                'margin-right': '14.3px',
                'margin-bottom': '9.7px',
                'margin-left': '0',
                'padding-top': '3%',
                'padding-right': '3%',
                'padding-bottom': '3%',
                'padding-left': '2%',
                'border-radius': '20px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
