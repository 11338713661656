import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { PremissionService } from 'src/Services/Premission/premission.service';
import { UserService } from 'src/Services/User/user.service';
import Langue from '../../../../../assets/langue.json';
@Component({
  selector: 'app-page-accueil-logistic',
  templateUrl: './page-accueil.component.html',
  styleUrls: ['./page-accueil.component.css'],
})
export class PageAccueilComponent implements OnInit,AfterViewInit  {
  @Input() profil: string;
  selectedIndex: any = 0;
  index: number;
  ShippingArrival_etat: boolean = false;
  Cargo_Manifest_etat: boolean = false;
  Shipping_Departure_etat: boolean = false;
  Ship_Docking_etat: boolean = false;
  Team_Manager: boolean = false;
  langue: any;
  direction: any;
  languef: any;
  languea: any;
  langue_list: any[] = Langue;
  constructor(
    private userService: UserService,
    private organisationService: OrganisationService,
    private PremisionApi: PremissionService,
    private spinner: NgxSpinnerService
  ) {
    this.selectedIndex = localStorage.getItem('redirecturl');
    console.log(" localStorage.getItem('redirecturl') " + localStorage.getItem('redirecturl'))

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].header;
      this.languea = this.langue_list[0]['AR'].SA;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].header;
      this.languea = this.langue_list[0]['EN'].SA;
    }
    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].header;
      this.languea = this.langue_list[0]['FR'].SA;
    }
  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {

    this.GetIdUser();
    this.PremisionApi.initializeDescription();
    this.selectedIndex = localStorage.getItem('redirecturl');
    this.ShippingArrival_etat = this.PremisionApi.GetShipArrival();
    this.Ship_Docking_etat = this.PremisionApi.GetShipDocking();
    this.Shipping_Departure_etat = this.PremisionApi.GetShipDeparture();
    this.Cargo_Manifest_etat = this.PremisionApi.GetShipCargo();
    this.Team_Manager = this.userService.ValidRole('Team manager');

    

    if (this.profil == undefined) {
      this.spinner.show();
      this.GetIdUser();
    }
    
    console.log(" localStorage.getItem('redirecturl') " + this.selectedIndex)
    console.log(" this.profil " + this.profil)
    console.log(" ShippingArrival_etat " + this.ShippingArrival_etat)
    console.log(" Ship_Docking_etat " + this.Ship_Docking_etat)
    console.log(" Shipping_Departure_etat " + this.Shipping_Departure_etat)
    console.log(" Cargo_Manifest_etat " + this.Cargo_Manifest_etat)
    console.log(" this.Team_Manager " + this.Team_Manager)
  }

  changesc(event: any) {
    localStorage.setItem('redirecturl', event.index);
  }
  UserId: any;
  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
  
      this.UserId = res.id;
      this.Getorganisation_send(this.UserId);
    });
  }
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.profil = res['profil'];
      console.log("                   profil            " + this.profil )
      this.spinner.hide();
    });
  }

  scenarioActiv: any;

  changeactive() {
  }
}
