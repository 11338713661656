import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PremissionService } from 'src/Services/Premission/premission.service';
import { CustomsService } from '../../Customs/Services/Customs/customs.service';
import { TechnicalService } from '../../Technical/Service/technical.service';
import Langue from './../../../../../assets/langue.json';

@Component({
  selector: 'app-dashboard-customs',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;

  CD_etat: boolean = false;
  TC_etat: boolean = false;

  constructor(
    private router: Router,
    private premissionApi: PremissionService,
    private customapi: CustomsService,
    private technicalapi: TechnicalService,
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].dashboard;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].dashboard;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].dashboard;
    }

    this.CD_etat = this.premissionApi.GetCustomsDeclaration();
    this.TC_etat = this.premissionApi.GetTechnicalControl();


  }

  ngOnInit(): void {

    this.Customs_declaration();
    this.technical_control();

  }

  navigation() {
    this.router.navigateByUrl('Customs/liste');
    localStorage.setItem('redirecturlC', '0');
  }
  navigationTc() {
    this.router.navigateByUrl('Customs/liste');
    localStorage.setItem('redirecturlC', '1');
  }

  cd_save: any = 0;
  cd_save_moy: any = 0;
  cd_forconf: any = 0;
  cd_forconf_moy: any = 0;
  cd_accept: any = 0;
  cd_accept_moy: any = 0;
  cd_refuse: any = 0;
  cd_refuse_moy: any = 0;
  cd_total: any = 0;
  cd_List: any[] = [];
  Customs_declaration() {
    this.customapi.GetByTeam().subscribe((res: any) => {
      this.cd_List = res;
      this.cd_total = this.cd_List.length;

      this.cd_save = this.cd_List.filter((e: any) => e.etat == 'Save').length;

      this.cd_save_moy = (this.cd_save / this.ts_total) * 100;
      this.cd_save_moy = this.cd_save_moy.toFixed(2);


      this.cd_forconf = this.cd_List.filter(
        (e: any) => e.etat == 'For confirmation'
      ).length;

      this.cd_forconf =
        this.cd_forconf +
        this.cd_List.filter((e: any) => e.etat == 'CompleteInfos').length +
        this.cd_List.filter((e: any) => e.etat == 'Payment').length +
        this.cd_List.filter((e: any) => e.etat == 'Confirm').length;

      this.cd_forconf_moy = (this.cd_forconf / this.cd_total) * 100;
      this.cd_forconf_moy = this.cd_forconf_moy.toFixed(2);


      this.cd_accept = this.cd_List.filter(
        (e: any) => e.etat == 'finalResult'
      ).length;


      this.cd_accept_moy = (this.cd_accept / this.cd_total) * 100;
      this.cd_accept_moy = this.cd_accept_moy.toFixed(2);



      this.cd_refuse = this.cd_List.filter(
        (e: any) => e.etat == 'Error'
      ).length;

      this.cd_refuse_moy = (this.cd_refuse / this.cd_total) * 100;
      this.cd_refuse_moy = this.cd_refuse_moy.toFixed(2);



      setTimeout(() => {
        this.skeleton = false
      }, 1000);


    });
  }
  ts_save: any = 0;
  ts_save_moy: any = 0;
  ts_forconf: any = 0;
  ts_forconf_moy: any = 0;
  ts_accept: any = 0;
  ts_accept_moy: any = 0;
  ts_refuse: any = 0;
  ts_refuse_moy: any = 0;
  ts_total: any = 0;
  ts_List: any[] = [];
  technical_control() {
    this.technicalapi.GetByTeam().subscribe((res: any) => {
      this.ts_List = res;
      this.ts_total = this.ts_List.length;
      this.ts_save = this.ts_List.filter((e: any) => e.etat == 'Save').length;
      this.ts_save =
        this.ts_save +
        this.ts_List.filter((e: any) => e.etat == 'Error').length;

      this.ts_save_moy = (this.ts_save / this.ts_total) * 100;
      this.ts_save_moy = this.ts_save_moy.toFixed(2);



      this.ts_forconf =
        this.ts_List.filter((e: any) => e.etat == 'For confirmation').length +
        this.ts_List.filter((e: any) => e.etat == 'CompleteInfos').length;

      this.ts_forconf_moy = (this.ts_forconf / this.ts_total) * 100;
      this.ts_forconf_moy = this.ts_forconf_moy.toFixed(2);

      this.ts_accept = this.ts_List.filter(
        (e: any) => e.etat == 'Approved'
      ).length;


      this.ts_accept_moy = (this.ts_accept / this.ts_total) * 100;
      this.ts_accept_moy = this.ts_accept_moy.toFixed(2);



      this.ts_refuse = this.ts_List.filter(
        (e: any) => e.etat == 'notApproved'
      ).length;


      this.ts_refuse_moy = (this.ts_refuse / this.ts_total) * 100;
      this.ts_refuse_moy = this.ts_refuse_moy.toFixed(2);


      setTimeout(() => {
        this.skeleton = false
      }, 1000);
    });


  }

  skeleton: boolean = true

}
