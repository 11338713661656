import { Injectable } from '@angular/core';
import { UserService } from '../User/user.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class PremissionService {
  generadtoken: any;
  description: any;
  constructor(private userauth: UserService) {
    let token = localStorage.getItem('token');

    if (token != null) {
      token = CryptoJS.AES.decrypt(
        token + '',
        'dfggf4257ttert1DFGS5451EDZ542ZAE777ds'
      ).toString(CryptoJS.enc.Utf8);
    }

    this.generadtoken = this.userauth.getDecodedAccessToken(token);
    if (this.generadtoken != null) {
      this.description = this.generadtoken['Description'];
    }
  }

  GetShipArrival() {
    let reponse = false;

    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SA') {
        localStorage.setItem('premission_SA', flux_r[2]);
        reponse = true;
      }
    }

    return reponse;
  }

  GetShipDeparture() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SDP') {
        localStorage.setItem('premission_SDP', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetShipDocking() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SD') {
        localStorage.setItem('premission_SD', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetShipCargo() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CM') {
        localStorage.setItem('premission_CO', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetTechnicalControl() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'TM') {
        localStorage.setItem('premission_TM', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  ValCD: any = [];
  ValSA: any = [];
  ValSDP: any = [];
  ValSD: any = [];
  ValCM: any = [];
  ValTO: any = [];
  ValFD: any = [];

  GetCustomsDeclaration() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CD') {
        localStorage.setItem('premission_CD', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }
  
  ValLC: any = [];
  ValCO: any = [];
  ValSG: any = [];

  GetCreditLetter() {
    let reponse = false;
    this.ValLC = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValLC = this.description.split(',');
      } else {
        this.ValLC[0] = this.description;
      }
    }

    for (let flux of this.ValLC) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'LC') {
        localStorage.setItem('premission_LC', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetCertificatOrigin() {
    let reponse = false;
    this.ValCO = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCO = this.description.split(',');
      } else {
        this.ValCO[0] = this.description;
      }
    }

    for (let flux of this.ValCO) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CO') {
        localStorage.setItem('premission_CO', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetShippingGarante() {
    let reponse = false;
    this.ValSG = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValSG = this.description.split(',');
      } else {
        this.ValSG[0] = this.description;
      }
    }

    for (let flux of this.ValSG) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SG') {
        localStorage.setItem('premission_CO', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }
}
