import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CargoSummaryService } from '../Service/CargoSummary/cargo-summary.service';
import { PassengerSummaryService } from '../Service/PassengerSummary/passenger-summary.service';
import { VesselCallInformationService } from '../Service/VesselCall/vessel-call-information.service';
import { VisitPurposeService } from '../Service/VisitPurpose/visit-purpose.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { jsPDF } from 'jspdf';
import { DatePipe } from '@angular/common';
import { MessageUserService } from 'src/Services/MessagesUser/message-user.service';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import Langue from '../../../../../assets/langue.json';
import { WebSocketSubject } from 'rxjs/webSocket';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/Services/User/user.service';
import { AffectationService } from 'src/Services/Affectation/affectation.service';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { NotifierService } from 'angular-notifier';
import { NotificationService } from 'src/Services/Notification/notification.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css'],
})
export class ListeComponent implements AfterViewInit, OnDestroy {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  langued: any;
  languem: any;
  languet: any;

  index: number = 0;
  displayedColumns: string[] = [
    'select',
    'name',
    'progress',
    'portd',
    'eta',
    'assign',
    'previous',
    'etat',
    'action',
  ];

  displayedColumnsActions: string[] = [
    'id',
    'status',
    'startDate',
    'affectedto',
    'action',
  ];
  dataSource: MatTableDataSource<any>;

  itemlist: any[];
  statuslist: any[];
  itemslist: any[];
  copylist: any[] = [];

  copylist_EN: any[] = [
    { name: 'Shipping Arrival', id: 1 },
    { name: 'Shipping Departure', id: 2 },
    { name: 'Cargo Manifest', id: 3 },
  ];
  copylist_AR: any[] = [
    { name: 'وصول السفينة', id: 1 },
    { name: 'مغادرة السفينة', id: 2 },
    { name: 'بيان السفينة', id: 3 },
  ];
  selectedtype: String | any;
  selectedstatus: String | any;
  selectedcondition: String | any;
  selectetcopy: String | any;
  CellList: any[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  vesselname: any;
  vesseltype: any;
  vesselbuildyear: any;
  vesselregport: any;
  vesseldraft: any;
  vessellength: any;
  vesselgrossweight: any;
  vesselnetweight: any;
  voyageno: any;
  callsigntype: any;
  callsign: any;
  ownercode: any;
  ownername: any;
  shippingagentcode: any;
  shippingagent: any;
  shipmaster: any;
  eta: any;
  etd: any;
  previousportcode: any;
  previousportname: any;
  portofcallcode: any;
  portofcallname: any;
  nextportcode: any;
  nextportname: any;
  preferreddock: any;
  berthtype: any;
  dangerousgoods: any;

  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  dataSourceMessages: MatTableDataSource<any>;
  constructor(
    private ArrCellInformationApi: VesselCallInformationService,
    private VisitPurposeApi: VisitPurposeService,
    private CargoSummaryApi: CargoSummaryService,
    private PassengerSummaryApi: PassengerSummaryService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private MessagesUser: MessageUserService,
    private router: Router,
    private titleService: Title,
    private userService: UserService,
    private affecationService: AffectationService,
    private organisationService: OrganisationService,
    private notifier: NotifierService,
    private notificationApi: NotificationService
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.langued = this.langue_list[0]['AR'].SA;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
      this.copylist = this.copylist_AR;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.copylist = this.copylist_EN;

      this.languef = this.langue_list[0]['EN'].tablelist;
      this.langued = this.langue_list[0]['EN'].SA;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.copylist = this.copylist_EN;

      this.languef = this.langue_list[0]['FR'].tablelist;
      this.langued = this.langue_list[0]['FR'].SA;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }
    this.titleService.setTitle(this.languet.entete);

    this.dataSource = new MatTableDataSource(this.CellList);
    this.RoleTeammanager = this.userService.ValidRole('Team manager');
    this.RoleAgent = this.userService.ValidRole('Agent');

    this.itemlist = [
      { id: 1, name: this.langued.eta },
      { id: 2, name: this.langued.etd },
    ];

    this.statuslist = [
      { id: 1, name: this.languet.save, code: 'Save' },
      { id: 2, name: this.languet.forconfirmation, code: 'For confirmation' },
      { id: 3, name: this.languet.accept, code: 'Accept' },
      { id: 4, name: this.languet.rejectd, code: 'Reject' },
    ];

    this.itemslist = [
      { name: this.langued.voyageno, code: 'voyageno', id: 1 },
      { name: this.langued.vesselname, code: 'vessel', id: 2 },
      { name: this.langued.ownerocde, code: 'ownercode', id: 1 },
      { name: this.langued.ownername, code: 'ownername', id: 1 },
      {
        name: this.langued.country,
        code: 'vesselcountryname',
        id: 3,
      },
      { name: this.langued.vesseltype, code: 'vesseltype', id: 4 },
      { name: this.langued.callsign, code: 'callsign', id: 1 },
      { name: this.langued.portofcallname, code: 'portofcallname', id: 1 },
      { name: this.langued.previousportname, code: 'previousportname', id: 1 },
      { name: this.langued.nextportname, code: 'nextportname', id: 1 },
    ];
  }
  ngOnDestroy(): void {
    this.websocket_etat == true;
  }

  ngAfterViewInit() {
    /*this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.GetIdUser();
    this.GetPremmision();
    this.GetPremmision();*/
    //this.Websocket_sa();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  list_delete: any[] = [];
  daterange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  onchangedatestart(event: any) {
    this.startdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  startdate: any;
  enddate: any;
  listrecherchr: any;
  onchangedateend(event: any) {
    this.enddate = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.websocket_etat = true;

    if (this.typerecherche == 1) {
      this.listrecherchr = this.CellList.filter(
        (e) => e.eta > this.startdate && e.eta < this.enddate
      );

      setTimeout(() => {
        this.CellList_1 = this.listrecherchr;
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else if (this.typerecherche == 2) {
      this.listrecherchr = this.CellList.filter(
        (e) => e.etd > this.startdate && e.etd < this.enddate
      );

      setTimeout(() => {
        this.CellList_1 = this.listrecherchr;
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else {
      this.GetArrCellInformation();
    }
  }
  Arr_remove() {
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));

    for (let cell of this.list_delete) {
      let cel = this.CellList_1.find((x) => x.id == cell.id);
      if (cell.etat == 'Save') {
        this.ArrCellInformationApi.delete(cel.id).subscribe((res: any) => { });
      }
    }
    setTimeout(() => {
      this.modalService.dismissAll();
      this.ClearAll();
    }, 300);
  }
  Arr_view(content: any, id: any) {
    this.ArrCellInformationApi.GetById(id).subscribe((res: any) => {
      this.Create_form(res);
      setTimeout(() => {
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 500);
    });
  }

  open(content: any) {
    this.modalService.open(content);
  }
  Arr_update() { }
  CellList_1: any[] = [];

  Create_form(call: any) {
    let vessel = call.vessel;
    this.vesselname = vessel.vessel;
    this.vesseltype = vessel.vesseltype;
    this.vesselbuildyear = vessel.vesselbuildyear;
    this.vesselregport = vessel.vesselregport;
    this.vesseldraft = vessel.vesseldraft;
    this.vessellength = vessel.vessellength;
    this.vesselgrossweight = vessel.vesselgrossweight;
    this.vesselnetweight = vessel.vesselnetweight;
    this.voyageno = vessel.voyageno;
    this.voyageno = call.voyageno;
    this.callsigntype = call.callsigntype;
    this.callsign = call.callsign;
    this.ownercode = call.ownercode;
    this.ownername = call.ownername;
    this.shippingagentcode = call.shippingagentcode;
    this.shippingagent = call.shippingagent;
    this.shipmaster = call.shipmaster;
    this.eta = call.eta;
    this.etd = call.etd;
    this.previousportcode = call.previousportcode;
    this.previousportname = call.previousportname;
    this.portofcallcode = call.portofcallcode;
    this.portofcallname = call.portofcallname;
    this.nextportcode = call.nextportcode;
    this.nextportname = call.nextportname;
    this.preferreddock = call.preferreddock;
    this.berthtype = call.berthtype;
    this.dangerousgoods = call.dangerousgoods;

    this.CargoSummarysList = call.arrVciCargoSummarys;
    this.PassengerSummarysList = call.arrVciPassengerSummarys;
    this.VisitPurposesList = call.arrVciVisitPurposes;
  }

  messageuser: any;

  CellList_2: any[] = [];
  GetArrCellInformation() {
    if (this.RoleTeammanager) {
      if (this.role_organisation == 'Shipping_agent') {
        this.ArrCellInformationApi.GetByteam('Ship_Arrival').subscribe(
          (res: any) => {
            this.Remplir_list(res);
          }
        );
      } else {
        this.ArrCellInformationApi.GetByOrg('Ship_Arrival').subscribe(
          (res: any) => {
            this.Remplir_list(res);
          }
        );
      }
    } else {
      this.ArrCellInformationApi.GetByUser('Ship_Arrival').subscribe(
        (res: any) => {
          console.log(res)
          this.Remplir_list(res);
        }
      );
    }
  }
exportByDate(content: any) {

  this.open(content);

}
  autoAdjustColumnWidth(data: any[], columnsMapping: { [key: string]: string }): any[] {
    const columnWidths: any[] = [];
    Object.keys(columnsMapping).forEach((key) => {
      const columnName = columnsMapping[key];
      const maxContentLength = data.reduce(
        (max, row) => Math.max(max, (row[columnsMapping[key]] || '').toString().length),
        columnName.length
      );
      columnWidths.push({ wch: maxContentLength + 2 });
    });
    return columnWidths;
  }
  exportToExcel(): void {
    
    const columnsMapping: { [key: string]: string } = {
      id: 'id',
      numEscale: 'Numéro Escale',
      applicationDate: 'Application Date',
      voyageno: 'Numéro Voyage',
      rejectionReason: 'Rejection Reason',
      ownercode: 'Owner Code',
      ownername: 'Owner Name',
      shippingagentcode: 'Shipping Agent Code',
      shippingagent: 'Shipping Agent',
      eta: 'ETA',
      etd: 'ETD',
      previousportcode: 'Previous Port Code',
      previousportname: 'Previous Port Name',
      portofcallcode: 'Port of Call Code',
      portofcallname: 'Port of Call Name',
      nextportcode: 'Code Port Destinataire',
      nextportname: 'Name Port Destinataire',
      dangerousgoods: 'Dangerous Goods',
      etat: 'État',
      type: 'Type',
      'arrVciVisitPurposes.reason': 'Reason Of Arrival Visa Control Information Visit Purposes',
      'arrVciPassengerSummarys.numberofpassenger': 'Number Of Passenger Of Arrival Visa Control Information Passenger Summaries',
      'arrVciCargoSummarys.cargocode': 'Cargo Code Of Arrival Visa Control Information Cargo Summaries',
      'vessel.vessel': 'Vessel Name',
    };

    const flattenedData = this.CellList_1.map((item: any) => {
      const flatItem: any = {};
      Object.keys(columnsMapping).forEach((key) => {

        if (key.includes('.')) {
          const [parentKey, childKey] = key.split('.');
          if (item[parentKey] && Array.isArray(item[parentKey])) {
            flatItem[columnsMapping[key]] = item[parentKey]
              .map((subItem: any) => subItem[childKey])
              .filter(Boolean)
              .join(', ');
          } else {
            flatItem[columnsMapping[key]] = item[parentKey][childKey] || '';
          }
        }
        else if (item[key] !== undefined) {
          if (Array.isArray(item[key])) {
            flatItem[columnsMapping[key]] = item[key]
              .map((subItem: any) => JSON.stringify(subItem))
              .join(', ');
          } else if (typeof item[key] === 'object') {
            flatItem[columnsMapping[key]] = JSON.stringify(item[key]);
          } else {
            flatItem[columnsMapping[key]] = item[key];
          }
        }
      });
      return flatItem;
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData);

    const columnWidths = this.autoAdjustColumnWidth(flattenedData, columnsMapping);
    worksheet['!cols'] = columnWidths;

    const workbook: XLSX.WorkBook = { Sheets: { DataSheet: worksheet }, SheetNames: ['DataSheet'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const dataBlob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, 'ExportedDataSA.xlsx');
  }




  affectedToUserName: any;
  Remplir_list(list: any) {

    this.CellList_2 = list;


    let resultA = this.CellList_2.filter(
      (elm) =>
        !this.CellList_1.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    let resultB = this.CellList_1.filter(
      (elm) =>
        !this.CellList_2.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    if (resultA.length > 0 || resultB.length > 0) {

      this.CellList_1 = this.CellList_2;
      this.CellList = this.CellList_2;

      this.CellList_1.forEach(item => {
        const lastMessage = item.messages?.[item.messages.length - 1];
        if (lastMessage?.actions?.length > 0) {
          const lastAction = lastMessage.actions[lastMessage.actions.length - 1];
          if (lastAction.affectedToUser) {
            this.organisationService.GetOrgByID(lastAction.affectedToUser).subscribe((res: any) => {
              this.affectedToUserName = res.userName
            });
          }
        }
      });

      this.dataSource = new MatTableDataSource(this.CellList_1);
      this.dataSource.paginator = this.paginator;
      this.CellList_2 = [];
    }
  }

  createnewCell(id: any, cell: any, vessel: any) {
    return {
      id: id,
      voyageno: cell.voyageno,
      vessel: vessel,
      port_declaration: cell.portofcallcode,
      eta: cell.eta,
      callsign: cell.callsign,
      port_previous: cell.nextportcode,
      etat: cell.etat,
    };
  }
  startDate = '';
  endDate = '';
  Save: any = 'Save';
  premission: any;
  @Input() profil: string;
  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.GetPremmision();
    this.GetPremmision();

    this.GetIdUser();

    console.log("this.profil on init " + this.profil)

  }
  typerecherche: any;
  changestatus(event: any) {
    if (event == undefined) {
      this.GetArrCellInformation();
    } else {
      this.websocket_etat = true;
      this.CellList_1 = this.CellList.filter((e) => e.etat == event.code);
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.CellList_1);
        this.dataSource.paginator = this.paginator;
      }, 500);
    }
  }
  valeurcondition: String;
  id_search: any;
  valeur: any = '';
  attr: any = '';
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.valeurcondition = '';
      this.GetArrCellInformation();
    } else {
      this.websocket_etat = true;
      this.id_search = event.id;
      this.attr = event.code;
    }
  }

  search() {
    if (this.id_search == 1) {
      this.ArrCellInformationApi.Serach(
        this.attr,
        this.valeurcondition
      ).subscribe((res: any) => {
        this.listrecherchr = res;
      });
    } else if (this.id_search == 2) {
      this.listrecherchr = this.CellList_1.filter(
        (e) => e.vessel.vessel == this.valeurcondition
      );
    } else if (this.id_search == 3) {
      this.listrecherchr = this.CellList_1.filter(
        (e) => e.vessel.vesselcountryname == this.valeurcondition
      );
    } else if (this.id_search == 4) {
      this.listrecherchr = this.CellList_1.filter(
        (e) => e.vessel.vesseltype == this.valeurcondition
      );
    }

    if (this.id_search != undefined) {
      this.CellList_1 = this.listrecherchr;
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 800);
    }
  }
  websocket_etat: boolean = false;
  changetype(event: any) {
    if (event == undefined || event == null) {
      this.GetArrCellInformation();
      this.endDate = '';
      this.startDate = '';
      this.websocket_etat = false;
      this.date_disabled = true;
      this.typerecherche = 0;
    } else {
      this.date_disabled = false;
      this.typerecherche = event.id;
    }
  }

  CargoSummarysList: any[] = [];
  PassengerSummarysList: any[] = [];
  VisitPurposesList: any[] = [];
  VisitPurpose_Delete(id: any) {
    this.VisitPurposeApi.delete(id).subscribe((res: any) => { });
  }

  CargoSummary_Delete(id: any) {
    this.CargoSummaryApi.delete(id).subscribe((res: any) => { });
  }
  PassengerSummary_Delete(id: any) {
    this.PassengerSummaryApi.delete(id).subscribe((res: any) => { });
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  ClearAll() {
    this.selection.clear();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  list_print: any[] = [];
  printdoc() {
    this.list_print = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let cell of this.list_print) {
      let cel = this.CellList_1.find((x) => x.id == cell.id);
      this.generate_pdf2(cel);
    }

    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }

  dimision() {
    this.modalService.dismissAll();
  }
  x: any = 0;
  y: any = 0;

  total_pass1: any = 0;
  total_pass2: any = 0;

  list_message: any;
  Arr_messages(content: any, id: any) {
    this.MessagesUser.Get(id, this.ArrCellInformationApi.Spring_serveur).subscribe((res: any) => {
      this.list_message = res;

      setTimeout(() => {
        this.dataSourceMessages = new MatTableDataSource(this.list_message);
        this.dataSourceMessages.paginator = this.paginator;
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 100);
    });
  }

  Go_copy() {
    if (this.selectetcopy.id == 3) {
      this.router.navigateByUrl(
        'CargoManifest/copy_shipping_arr/' + this.id_ship_copy
      );
    }

    if (this.selectetcopy.id == 2) {
      this.router.navigateByUrl(
        'ShippingDeparture/copy_shipping_arr/' + this.id_ship_copy
      );
    }
    if (this.selectetcopy.id == 1) {
      this.router.navigateByUrl('ShippingArrival/copy/' + this.id_ship_copy);
    }

    this.dimision();
  }
  changestscenario(event: any) { }

  id_vessel_copy: any;
  id_ship_copy: any;
  open_copy(id_vessel: any, id_ship: any, content: any) {
    this.open(content);

    this.id_ship_copy = id_ship;
  }

  generate_pdf2(cell: any) {
    this.total_pass1 = 0;
    this.total_pass2 = 0;
    this.y = 0;
    var doc = new jsPDF();
    var img = new Image();
    img.src = './../../../../assets/nexgen.png';
    doc.setFont('italic');
    doc.addImage(img, 'png', 150, 5, 50, 20);
    doc.setFontSize(10);
    doc.setTextColor('#46B1E3');
    doc.text('Voyage No :' + cell.voyageno, 5, 10);
    doc.text('Shipping Company :' + '_', 5, 15);
    doc.setFontSize(20);
    doc.text('Shipping Arrival', 75, 15);
    doc.setFontSize(11);
    this.y = 40;
    let x1 = 10;
    let x2 = 60;
    let x3 = 110;
    let x4 = 150;
    /*   doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('Vessel Call Information', 10, 40); */

    doc.setDrawColor('#0000FF');
    doc.line(5, 43, 5, 290);
    doc.line(5, 43, 205, 43);
    doc.line(205, 43, 205, 290);
    doc.line(5, 290, 205, 290);

    doc.line(105, 43, 105, 52);
    doc.line(5, 52, 205, 52);
    doc.setFontSize(8);
    doc.setTextColor('#1D3A66');
    doc.text('Vessel Information' + cell.vessel.vesselregport, 40, 50);
    doc.text('General Information' + cell.vessel.vesselregport, 140, 50);

    doc.line(105, 52, 105, 67);
    doc.line(45, 52, 45, 67);
    doc.line(75, 52, 75, 67);

    doc.line(165, 52, 165, 67);
    doc.line(135, 52, 135, 67);

    doc.line(5, 67, 205, 67);

    doc.line(105, 67, 105, 82);

    doc.line(55, 67, 55, 82);

    doc.line(165, 67, 165, 82);
    doc.line(135, 67, 135, 82);

    doc.line(5, 82, 205, 82);

    doc.line(45, 82, 45, 97);
    doc.line(75, 82, 75, 97);

    doc.line(5, 97, 205, 97);

    doc.line(105, 82, 105, 97);
    doc.line(170, 82, 170, 97);
    doc.line(130, 82, 130, 97);

    doc.line(105, 97, 105, 112);
    doc.line(55, 97, 55, 112);

    doc.line(140, 97, 140, 112);
    doc.line(165, 97, 165, 112);
    doc.line(5, 112, 205, 112);
    //bloc1
    doc.text('Vessel Name', 7, 57);
    doc.text('Vessel Type ', 47, 57);
    doc.text('Vessel Build Year', 77, 57);
    doc.text('Voyage no', 107, 57);
    doc.text('Ship Agent', 137, 57);
    doc.text('Port Of Call', 167, 57);

    //bloc2

    doc.text('Vessel Country Name', 7, 71);
    doc.text('Vessel Country Code', 57, 71);
    doc.text('Owner Name', 107, 71);
    doc.text('Previous Port Name', 167, 71);
    doc.text('Next Port Name', 137, 71);

    //bloc3
    doc.text('Vessel Draft', 7, 86);
    doc.text('Vessel Length ', 47, 86);
    doc.text('Vessel Reg Port', 77, 86);
    doc.text('ETA', 107, 86);
    doc.text('ETD', 135, 86);
    doc.text('Call sign', 171, 86);

    //bloc4

    doc.text('Vessel Gross Weight', 7, 101);
    doc.text('Vessel Net Weight', 57, 101);

    doc.text('Dangerous goods', 107, 101);
    doc.text('Preferred Dock', 141, 101);
    doc.text('Etat', 167, 101);

    doc.line(5, 125, 205, 125);
    doc.line(5, 135, 205, 135);
    doc.line(40, 125, 40, 170);
    doc.line(85, 125, 85, 170);
    doc.line(5, 170, 205, 170);
    doc.setTextColor('#1D3A66');
    doc.setFontSize(10);

    doc.text('Passenger Summary', 95, 118);
    doc.setFontSize(8);
    doc.text('reference', 8, 131);
    doc.text('Passenger Nationally', 45, 131);
    doc.text('Number of Passenger', 90, 131);
    doc.setTextColor('#000');
    doc.setFontSize(9);

    //Ecriture !
    //bloc1
    doc.text(cell.vessel.vessel + '', 9, 64);
    doc.text(cell.vessel.vesseltype + '', 49, 64);
    doc.text(cell.vessel.vesselbuildyear + '', 79, 64);
    doc.text(cell.voyageno + '', 109, 64);
    doc.text(cell.shippingagent + '', 139, 64);
    doc.text(cell.portofcallname + '', 169, 64);

    //bloc2

    doc.text(cell.vessel.vesselcountryname + '', 9, 78);
    doc.text(cell.vessel.vesselcountrycode + '', 59, 78);
    doc.text(cell.ownername + '', 109, 78);
    doc.text(cell.nextportname + '', 169, 78);
    doc.text(cell.previousportname + '', 139, 78);

    //bloc3
    doc.text(cell.vessel.vesseldraft + '', 9, 93);
    doc.text(cell.vessel.vessellength + '', 49, 93);
    doc.text(cell.vessel.vesselregport + '', 79, 93);
    doc.text(cell.eta + '', 109, 93);
    doc.text(cell.etd + '', 137, 93);
    doc.text(cell.callsign + '', 173, 93);

    //bloc4

    doc.text(cell.vessel.vesselgrossweight + '', 9, 108);
    doc.text(cell.vessel.vesselnetweight + '', 59, 108);

    doc.text(cell.dangerousgoods + '', 109, 108);
    doc.text(cell.preferreddock + '', 143, 108);
    doc.text(cell.etat + '', 169, 108);
    doc.line(5, 180, 205, 180);
    doc.line(5, 190, 205, 190);
    doc.line(5, 200, 205, 200);
    doc.line(40, 190, 40, 230);
    doc.line(5, 230, 205, 230);

    doc.line(5, 245, 205, 245);
    doc.line(5, 255, 205, 255);
    doc.line(40, 245, 40, 290);
    doc.line(80, 245, 80, 290);
    doc.line(130, 245, 130, 290);
    doc.line(5, 290, 205, 290);

    doc.setTextColor('#1D3A66');
    doc.setFontSize(10);

    doc.text('Visit Purpose', 95, 185);
    doc.setFontSize(8);
    doc.text('reference', 8, 195);
    doc.text('Reason', 105, 195);

    doc.setTextColor('#1D3A66');
    doc.setFontSize(10);

    doc.text('Cargo Summay', 95, 240);
    doc.setFontSize(8);
    doc.text('reference', 8, 250);
    doc.text('Cargo Code', 45, 250);
    doc.text('Cargo Amount', 85, 250);
    doc.text('Operation Code ', 135, 250);

    doc.line(105, 170, 105, 180);
    doc.setTextColor('#000');
    doc.setFontSize(9);

    let f = 260;
    let r = 205;
    let e = 140;
    for (let cell1 of cell.arrVciVisitPurposes) {
      doc.text(cell1.id + ' ', 15, r);
      doc.text(cell1.reason + ' ', 45, r);
      r = r + 7;
    }

    for (let cell3 of cell.arrVciPassengerSummarys) {
      doc.text(cell3.id + ' ', 9, e);
      doc.text(cell3.passengernationally + ' ', 47, e);

      doc.text(cell3.numberofpassenger + ' ', 92, e);

      if (cell3.passengernationally == 'Libya') {
        this.total_pass1 = this.total_pass1 + Number(cell3.numberofpassenger);
      } else {
        this.total_pass2 = this.total_pass2 + Number(cell3.numberofpassenger);
      }
      e = e + 7;
    }

    doc.text('Total Libyan Passenger : ' + this.total_pass1 + ' ', 10, 175);
    doc.text(
      'Total foreigner Passenger  : ' + this.total_pass2 + ' ',
      115,
      175
    );

    for (let cell2 of cell.arrVciCargoSummarys) {
      doc.text(cell2.id + ' ', 15, f);
      doc.text(cell2.cargocode + ' ', 45, f);
      doc.text(cell2.cargoamount + ' ', 85, f);
      doc.text(cell2.operationcode + ' ', 135, f);
      f = f + 7;
    }

    doc.save('SHipping_Arrival_num' + cell.voyageno + '.pdf');
  }

  reason: any;
  open_reason(content: any, reason: any) {
    this.reason = reason;
    this.open(content);
  }

  ecriture: boolean = false;
  GetPremmision() {
    let premission = localStorage.getItem('premission_SA');

    if (premission?.indexOf('E') != -1) {
      this.ecriture = true;
    }
  }

  private socket: any;
  web_socket: boolean = false;

  Websocket_sa() {
    var host = 'wss://ws.coincap.io/prices?assets=bitcoin,ethereum,litecoin';

    if (this.selection.selected.length > 0) {
      this.web_socket = true;
    }

    this.socket = new WebSocketSubject(host);

    this.socket.subscribe(
      (message: any) => { },
      (err: any) => console.log(err)
    );

    this.socket = new WebSocket(host);
    this.socket.onmessage = (e: any) => {
      if (this.websocket_etat == false) {
        this.GetArrCellInformation();
      }
    };
  }

  date_disabled: boolean = true;

  changeaction(event: any) {
    this.userslist = [];
    this.selectetuser = null;
    if (event == undefined) {
      this.userslist = [];
    }

    if (event.id == '1') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SAVE';
    } else if (event.id == '2') {
      this.getUser(this.TeamId, this.premission + 'S');
      this.acions_t = 'SUBMIT';
    }
  }

  dataSourceActions: MatTableDataSource<any>;
  listactions: any[];
  id_folder: any;
  message_user: any;
  reason_assign: any;
  messages_actions: any[] = [];
  message_sender: any;
  message_receiver: any;
  Arr_assign(content: any, id_folder: any, document: any) {
    this.transactiontype = 'Save';
    this.status_sc = false;
    this.id_folder = id_folder;
    let row_message = document.messages.length - 1;
    let status_message = document.messages[row_message].status;
    this.message_user = document.messages[row_message];
    this.id_message = document.messages[row_message].id;

    if (this.message_user.organizationSender != null) {
      this.message_sender = this.message_user.organizationSender;
    }

    if (this.message_user.organizationReceiver != null) {
      this.message_receiver = this.message_user.organizationReceiver;
    }

    let conditionReceiver =
      document.etat == 'For confirmation' &&
      this.message_receiver == this.id_Org_en;

    let conditionSender =
      document.etat == 'Save' && this.message_sender == this.id_Org_en;

    if (status_message == 'SUCCESS' || status_message == 'INPROGRESS') {
      if (conditionReceiver || conditionSender) {
        this.status_sc = false;
        this.messages_actions = this.message_user.actions;

        if (conditionReceiver) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }

        if (conditionSender) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }
        let row_actions = this.messages_actions.length;
        if (row_actions > 0) {
          if (this.messages_actions[row_actions - 1].status != 'FINISHED') {
            this.status_sc = true;
            this.reason_assign = this.languet.text_assign_2;
          }
        }

        setTimeout(() => {
          this.dataSourceActions = new MatTableDataSource(
            this.messages_actions
          );
        }, 200);
      } else {
        this.status_sc = true;
        this.reason_assign = this.languet.text_assign_1;
      }
    } else {
      this.status_sc = true;
      this.reason_assign = this.languet.text_assign_1;
    }

    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 500);
  }

  selectetassign: any;

  actionlist: any[];

  selectetuser: any;
  Id_user: any;
  acions_t: any;
  userslist: any[];

  changeuser(event: any) {
    this.Id_user = event.id;
  }

  getUser(team: any, sc: any) {
    this.userService.GetAllUsers(team, sc).subscribe((res: any) => {
      console.log(" team " + team + " sc " + sc)
      console.log(" this.userslist " + res)
      this.userslist = res;
    });
  }

  Assign() {
    if (this.transactiontype == 'Save') {
      this.actiondata = {
        code: this.acions_t,
        affectedToUser: this.Id_user,
        designation: this.designation,
      };

      this.affecationService
        .assignm(this.ArrCellInformationApi.Spring_serveur, this.actiondata, this.id_folder, 'arr')
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();

          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    } else {
      this.actiondata.code = this.acions_t;
      this.actiondata.affectedToUser.id = this.Id_user;
      this.actiondata.designation = this.designation;

      this.affecationService
        .reassign(this.ArrCellInformationApi.Spring_serveur, this.actiondata, this.id_Action)
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    }
  }
  TeamId: any;
  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
      this.TeamId = res.team.id;

      this.Getorganisation_send(res.id);
    });
  }
  designation: any;
  id_message: any;

  color_active = '#77838e';
  status_sc: boolean = false;
  RoleTeammanager: boolean = false;
  RoleAgent: boolean = false;
  id_Org_en: any;

  role_organisation: any;
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.role_organisation = res['profil'];
      this.profil = res['profil'];
      console.log(" this.profil 22 " + this.profil)

      if (this.profil == 'Port') {
        this.premission = 'SA_A2.1_LE';
        console.log("this.premission " + this.premission)
        this.actionlist = [{ id: '2', name: this.languef.submit }];
      } else {
        this.premission = 'SA_A1.1_LE';
        this.actionlist = [
          // { id: '1', name: this.languef.save },
          { id: '2', name: this.languef.submit },
        ];
      }

      this.GetArrCellInformation();
      this.id_Org_en = res.id;
    });
  }

  document: any;
  nomOrgSender: String;
  nomOrgReciever: String;
  submit(content: any, row: any) {
    this.document = row;
    this.document.etat = 'For confirmation';
    this.ArrCellInformationApi.submit(
      this.document,
      row.portofcallcode
    ).subscribe(async (res: any) => {
      this.open(content);

      try {

        const [nomOrgSender, nomOrgReceiver] = await Promise.all([
          this.getInformationOrg(this.document.messages[0].organizationSender),
          this.getInformationOrg(this.document.messages[0].organizationReceiver)
        ]);

        this.nomOrgSender = nomOrgSender;
        this.nomOrgReciever = nomOrgReceiver;

        const formdata = {
          action: 'SUBMIT',
          idOrgSender: this.document.messages[0].organizationSender,
          nomOrgSender: this.nomOrgSender,
          idOrgReceiver: this.document.messages[0].organizationReceiver,
          nomOrgReceiver: this.nomOrgReciever,
          idScenario: this.document.id,
        };

        this.notificationApi.post(formdata).subscribe((res: any) => {
        });

      } catch (error) {
        console.error("Error fetching organization information:", error);
      }
    });
  }

  transactiontype = 'Save';
  id_Action: any;
  action: any;
  useraction: any;
  actiondata: any;

  reassign(action: any) {
    this.transactiontype = 'Update';
    this.action = action;
    this.actiondata = action;
    this.id_Action = action.id;
    this.useraction = action.affectedToUser;
    this.selectetuser = this.useraction.userName;
    this.Id_user = this.selectetuser.id;
    this.selectetassign = this.action.code;
    this.designation = this.action.designation;
    this.status_sc = false;
  }


  getInformationOrg(id: any): Promise<string> {
    return new Promise((resolve, reject) => {
      this.organisationService.GetInformationOrganisationByIdOrg(id)
        .subscribe({
          next: (response: any) => resolve(response.nameOrg),
          error: (error) => reject(error)
        });
    });
  }

}
