import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ArchwizardModule } from 'angular-archwizard';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NewComponent } from './new/new.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthGuard } from 'src/Services/Guard/auth.guard';
import { TradingGuard } from 'src/Services/Roles/Trading/trading.guard';
import { ListeComponent } from './liste/liste.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 30,
    },
    vertical: {
      position: 'top',
      distance: 90,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};
@NgModule({
  declarations: [NewComponent, ListeComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    FontAwesomeModule,
    NotifierModule.withConfig(customNotifierOptions),
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    HttpClientModule,
    MatDividerModule,
    MatTooltipModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ArchwizardModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgSelectModule,
    MatStepperModule,
    MatRadioModule,
    MatCardModule,
    NgxSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    PdfViewerModule,
    BsDropdownModule.forRoot(),
    MatSelectCountryModule.forRoot('en'),
    RouterModule.forChild([
      {
        path: 'CertificatOrigin/new',
        component: NewComponent,
        canActivate: [AuthGuard],
      },

      {
        path: 'CertificatOrigin/update/:id',
        component: NewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'CertificatOrigin/copy/:id1',
        component: NewComponent,
        canActivate: [AuthGuard],
      },
    ]),
  ],
  exports: [ListeComponent],
  providers: [DatePipe],
})
export class CertificatOrigintModule {}
