<div class="navbar" [ngClass]="{ expanded: isMenuVisible }">
  <div class="logo">
    <img src="../../../../assets/logo_dark.png" width="150px" />
    <img
      src="../../../assets/icons/menu.png"
      *ngIf="!isMenuVisible"
      class="menu"
      (click)="toggleMenu()"
    />
    <img
      src="../../../assets/icons/x.png"
      *ngIf="isMenuVisible"
      class="menu"
      (click)="toggleMenu()"
    />
  </div>
  <div class="second_column" [ngClass]="{ show: isMenuVisible }">
    <!-- Home -->
    <div class="itemflex">
      <a
        (click)="navigate('/')"
        class="itemflex link"
        style="color: #111111; width: 110px"
        >{{ langueh.home }}</a
      >
    </div>
    <!-- E-export -->
    <!-- E-Logistic -->
    <div *ngIf="item === 'no'" class="itemflex">
      <a class="itemflex" style="color: #111111; width: 110px">{{
        langueh.logistic
      }}</a>
      <ul class="dropdown-cnt">
        <li>
          <a style="font-size: 14px">Shipping Arrival</a>
        </li>
        <li>
          <a style="font-size: 14px">Ship Docking</a>
        </li>
        <li>
          <a style="font-size: 14px">Shipping Departure</a>
        </li>
        <li>
          <a style="font-size: 14px">Cargo Manifest</a>
        </li>
      </ul>
    </div>
    <!-- E-trade -->
    <div *ngIf="item === 'no'" class="itemflex">
      <a
        (click)="onClickScroll('e-trade')"
        class="itemflex"
        style="color: #111111; width: 110px"
        >{{ langueh.trade }}</a
      >
      <ul class="dropdown-cnt">
        <li>
          <a style="font-size: 14px" (click)="onClickScroll('e-trade')">{{
            langueh.lc
          }}</a>
        </li>
        <li>
          <a style="font-size: 14px" (click)="onClickScroll('e-trade')">{{
            langueh.co
          }}</a>
        </li>
        <li>
          <a style="font-size: 14px" (click)="onClickScroll('e-trade')">{{
            langueh.sg
          }}</a>
        </li>
      </ul>
    </div>

    <!-- E-fret -->

    <!-- E-customs -->
    <div *ngIf="item === 'no'" class="itemflex">
      <a
        (click)="onClickScroll('costum-sec')"
        class="itemflex"
        style="color: #111111; width: 130px"
        >{{ langueh.customs }}</a
      >
      <ul class="dropdown-cnt">
        <li>
          <a style="font-size: 14px" (click)="onClickScroll('costum-sec')">{{
            langueh.cd
          }}</a>
        </li>

        <li>
          <a style="font-size: 14px" (click)="onClickScroll('costum-sec')">{{
            langueh.tc
          }}</a>
        </li>
      </ul>
    </div>
    <!-- partenaires -->
    <div *ngIf="item === 'no'" class="itemflex">
      <a href="#" class="itemflex" style="color: #111111; width: 170px"
        >Nos partenaires</a
      >
      <ul class="dropdown-cnt" style="width: 200px">
        <li style="display: flex">
          <a href="#" style="font-size: 14px; width: 210px">CEPEX</a>
        </li>
        <li style="display: flex">
          <a
            href="#"
            style="font-size: 14px; width: 210px"
            (click)="onClickScroll('carousel')"
            >COTUNACE</a
          >
        </li>
        <li style="display: flex">
          <a
            href="#"
            style="font-size: 14px; width: 210px"
            (click)="onClickScroll('carousel')"
            >STB</a
          >
        </li>
        <li style="display: flex">
          <a
            href="#"
            style="font-size: 14px; width: 210px"
            (click)="onClickScroll('carousel')"
            >Transitaire</a
          >
        </li>
        <li style="display: flex">
          <a
            href="#"
            style="font-size: 14px; width: 210px"
            (click)="onClickScroll('carousel')"
            >Conséller a l'export</a
          >
        </li>
        <li style="display: flex">
          <a
            href="#"
            style="font-size: 14px; width: 210px"
            (click)="onClickScroll('carousel')"
            >Fret Maritime</a
          >
        </li>
        <li style="display: flex">
          <a
            href="#"
            style="font-size: 14px; width: 210px"
            (click)="onClickScroll('carousel')"
            >Fret Aérien</a
          >
        </li>
        <li style="display: flex">
          <a
            href="#"
            style="font-size: 14px; width: 210px"
            (click)="onClickScroll('carousel')"
            >Transporteurs Terrestre</a
          >
        </li>
      </ul>
    </div>
    <div *ngIf="item === 'no'" class="itemflex">
      <a
        (click)="navigate('contact')"
        class="itemflex"
        style="color: #111111; width: 170px"
        >Contact</a
      >
    </div>
  </div>
  <div class="last_column" [ngClass]="{ show: isMenuVisible }">
    <div
      style="
        padding-right: 1%;
        padding-left: 1%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <a
        class="dropdown"
        style="display: flex; margin-left: 5px; margin-top: 6%"
      >
        <div>
          <div style="display: flex">
            <img src="../../../../assets/icons/ICONS.png" />
            <span
              style="
                padding-left: 7%;
                padding-right: 7%;
                font-size: 16px;
                color: #111111;
              "
              >{{ langue_nom }}</span
            >
          </div>
          <div class="dropdown-content1" [ngStyle]="{ direction: direction }">
            <a
              *ngIf="langue == 'AR' || langue == 'FR'"
              style="display: flex; border-bottom: solid 1px #1671b3"
              (click)="changelangue('EN')"
            >
              <div></div>
              <div style="margin-left: 5px; display: flex">
                <img
                  src="./../../../assets/svg-country-flags/svg/gb-eng.svg"
                  width="25px"
                />
                <span style="font-size: 15px; padding: 8%">{{
                  langueh.english
                }}</span>
              </div>
            </a>
            <a
              *ngIf="langue == 'EN' || langue == 'FR'"
              (click)="changelangue('AR')"
              style="display: flex"
            >
              <div></div>
              <div style="margin-left: 5px; display: flex">
                <img
                  src="./../../../assets/svg-country-flags/svg/tn.svg"
                  width="25px"
                />
                <span style="font-size: 15px; padding: 8%">{{
                  langueh.arabic
                }}</span>
              </div>
            </a>
            <a
              *ngIf="langue == 'EN' || langue == 'AR'"
              (click)="changelangue('FR')"
              style="display: flex"
            >
              <div></div>
              <div style="margin-left: 5px; display: flex">
                <img
                  src="./../../../assets/svg-country-flags/svg/fr.svg"
                  width="30px"
                />
                <span style="font-size: 15px; padding: 8%">{{
                  langueh.france
                }}</span>
              </div>
            </a>
          </div>
        </div>
        <div style="padding-right: 3%; padding-left: 3%">
          <img src="../../../../assets/icons/explore.png" />
        </div>
      </a>
    </div>
    <div class="buttonG">
      <input
        type="button"
        [value]="langueh.login"
        class="login_button"
        (click)="navigate('Auth/login')"
      />
      <button class="sign_up_button">
        <a
          class="sign_up_button"
          style="text-decoration: none; color: #fff"
          href="http://146.59.241.63:5001/inscription"
        >
          {{ langueh.signup }}
        </a>
      </button>
    </div>
  </div>
</div>
