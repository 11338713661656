import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import { MessageUserService } from 'src/Services/MessagesUser/message-user.service';
import { TechnicalService } from '../Service/technical.service';
import Langue from './../../../../../assets/langue.json';
import { WebSocketSubject } from 'rxjs/webSocket';
import { AffectationService } from 'src/Services/Affectation/affectation.service';
import { UserService } from 'src/Services/User/user.service';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { NotificationService } from 'src/Services/Notification/notification.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-liste-t',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css'],
})
export class ListeComponent implements OnInit {
  index: number = 0;
  displayedColumns: string[] = [
    'select',
    'id',
    'date',
    'banque',
    'tc',
    'ac',
    'asf',
    'pays',
    'assign',
    'etat',
    'action',
  ];

  itemlist = [
    { name: 'Application Date', code: 'applicationDate' },
    { name: 'Presentation DocumentDate', code: 'presentationDocumentDate' },
    { name: 'Arrival Notice Date', code: 'arrivalNoticeDate' },
    { name: 'Actual Arrival Date', code: 'actualArrivalDate' },
  ];

  displayedColumnsActions: string[] = [
    'id',
    'status',
    'startDate',
    'affectedto',
    'action',
  ];
  onchangedatestart(event: any) {
    this.startdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  id_search: any = 0;
  typerecherche: any;
  date_disabled: boolean = true;
  changedate(event: any) {
    if (event != undefined) {
      this.date_disabled = false;
      this.typerecherche = event.code;
    } else {
      this.id_search = 0;
      this.startDate = '';
      this.endDate = '';
      this.date_disabled = true;
      this.GetTechnicalControl();
    }
  }
  startdate: any;
  enddate: any;

  selecteddate: any;
  startDate: any;
  endDate: any;
  selectedstatus: any;
  statuslist = [
    'Save',
    'For confirmation',
    'CompleteInfos',
    'Error',
    'Confirm',
    'Approved',
    'notApproved',
  ];
  itemslist = [
    { name: 'Branch Name', code: 'branchName' },
    { name: 'Trade Company Name', code: 'tradeCompanyName' },
    { name: 'Agent Company Name', code: 'agentCompanyName' },
    { name: 'Agent Staf Name', code: 'agentStaffame' },
    { name: 'Hs Name', code: 'hsName' },
    { name: 'Item Name', code: 'itemName' },
    { name: 'Quantity', code: 'quantity' },
    { name: 'Method Name', code: 'methodName' },
    { name: 'Country Name', code: 'countryName' },
    { name: 'Item Shipping Amount', code: 'itemShippingAmount' },
    {
      name: 'Item Shipping Amount Currency Unit',
      code: 'temShippingAmountCurrencyUnit',
    },
    { name: 'Currency Exchange', code: 'currencyExchange' },
    { name: 'Goods Foreign Amount', code: 'goodsForeignAmount' },
    { name: 'Dinar Amount', code: 'dinarAmount' },
    { name: 'actualArrivalPlace', code: 'Actual Arrival Place' },
    { name: 'Bl Number', code: 'blnumber' },
    { name: 'Remark', code: 'remark' },
    { name: 'Inspector Name', code: 'inspectorName' },
  ];
  selectedcondition: any;
  valeurcondition: any;
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.id_search = 0;
      this.valeurcondition = '';
      this.GetTechnicalControl();
    } else {
      this.attr = event.code;
    }
  }
  etat: any;
  changestatus(event: any) {
    if (event != undefined) {
      this.etat = event;
      this.search_status();
    } else {
      this.id_search = 0;
      this.GetTechnicalControl();
    }
  }

  search_status() {
    this.id_search = 3;
    this.ListTechnical_r = this.ListTechnical_f.filter(
      (e) => e.etat == this.etat
    );
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.ListTechnical_r);
      this.dataSource.paginator = this.paginator;
    }, 100);
  }
  onchangedateend(event: any) {
    this.enddate = this.datePipe.transform(event, 'yyyy-MM-dd');

    this.serach_date();
  }

  ListTechnical_r: any;
  serach_date() {
    this.id_search = 2;
    if (this.typerecherche == 'applicationDate') {
      this.ListTechnical_r = this.ListTechnical_f.filter(
        (e) =>
          e.applicationDate > this.startdate && e.applicationDate < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListTechnical_r);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else if (this.typerecherche == 'presentationDocumentDate') {
      this.ListTechnical_r = this.ListTechnical_f.filter(
        (e) =>
          e.presentationDocumentDate > this.startdate &&
          e.presentationDocumentDate < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListTechnical_r);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else if (this.typerecherche == 'arrivalNoticeDate') {
      this.ListTechnical_r = this.ListTechnical_f.filter(
        (e) =>
          e.arrivalNoticeDate > this.startdate &&
          e.arrivalNoticeDate < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListTechnical_r);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else if (this.typerecherche == 'actualArrivalDate') {
      this.ListTechnical_f = this.ListTechnical_f.filter(
        (e) =>
          e.actualArrivalDate > this.startdate &&
          e.actualArrivalDate < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListTechnical_r);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else {
      this.GetTechnicalControl();
    }
  }

  dataSource: MatTableDataSource<any>;

  displayedColumnsP: string[] = ['file', 'actions'];
  dataSourcep: MatTableDataSource<any>;
  ListCustoms: any[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;

  constructor(
    private titleService: Title,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private userService: UserService,
    private affecationService: AffectationService,
    private messageuser: MessageUserService,
    public sanitizer: DomSanitizer,
    private technicalService: TechnicalService,
    private organisationService: OrganisationService,
    private notifier: NotifierService,
    private notificationApi: NotificationService
  ) {
    this.dataSource = new MatTableDataSource(this.ListTechnical_f);

    this.dataSource = new MatTableDataSource(this.Messages_list);

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].TC;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].TC;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].TC;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }

    this.titleService.setTitle(this.languet.entete);
    this.actionlist = [
      { id: '1', name: this.languef.save },
      { id: '2', name: this.languef.submit },
    ];

    this.RoleTeammanager = this.userService.ValidRole('Team manager');
    this.RoleTrading = this.userService.ValidRole('Trading Company');
  }
  actionlist: any[];
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  list_delete: any[] = [];

  Arr_remove() {
    this.modalService.dismissAll();
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));

    for (let technical of this.list_delete) {
      let technical_d = this.ListTechnical_f.find((x) => x.id == technical.id);
      if (technical_d.etat == 'Save') {
        this.technicalService
          .delete(technical_d.id)
          .subscribe((res: any) => { });
      }
    }
    setTimeout(() => {
      this.GetTechnicalControl();
      this.ClearAll();
    }, 300);
  }
  document: any;
  Arr_view(content: any, id: any) {
    this.technicalService.GetByid(id).subscribe((res: any) => {
      this.document = res;
      setTimeout(() => {
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 300);
    });
  }

  open(content: any) {
    this.modalService.open(content);
  }
  Arr_update() { }

  ListTechnical_f: any[] = [];
  ListTechnical_1: any[] = [];
  ListTechnical_2: any[] = [];
  GetTechnicalControl() {
    if (this.RoleTeammanager) {
      if (this.role_organisation == 'Trading_Company') {
        this.technicalService.GetByTeam().subscribe((res: any) => {
          this.remplir_list(res);
        });
      } else {
        this.technicalService.GetByOrg().subscribe((res: any) => {
          this.remplir_list(res);
        });
      }
    } else {
      this.technicalService.GetByUser().subscribe((res: any) => {
        this.remplir_list(res);
      });
    }
  }

  remplir_list(list: any) {
    this.ListTechnical_2 = list;

    let resultA = this.ListTechnical_2.filter(
      (elm) =>
        !this.ListTechnical_1.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    let resultB = this.ListTechnical_1.filter(
      (elm) =>
        !this.ListTechnical_2.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    if (resultA.length > 0 || resultB.length > 0) {
      this.ListTechnical_1 = this.ListTechnical_2;
      this.ListTechnical_f = this.ListTechnical_2;
      this.dataSource = new MatTableDataSource(this.ListTechnical_1);
      this.dataSource.paginator = this.paginator;
      this.ListTechnical_2 = [];
    }
  }
  Article_DATA: any[] = [];

  dataSourceArticles: MatTableDataSource<any>;
  ngOnInit(): void {
    this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
    this.GetIdUser();
    //this.Websocket_technical();

    if (this.profil == 'Customs') {
      this.premission = 'TM_A2.1_LE';
      this.actionlist = [{ id: '2', name: this.languef.submit }];
    } else {
      this.premission = 'TM_A1.1_LE';
      this.actionlist = [
        // { id: '1', name: this.languef.save },
        { id: '2', name: this.languef.submit },
      ];
    }
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  ClearAll() {
    this.selection.clear();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  list_print: any[] = [];
  printdoc() {
    this.list_print = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let tce of this.list_print) {
      let titre = this.ListTechnical_f.find((x) => x.id == tce.id);
      this.generate_pdf(titre);
    }

    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }

  dimision() {
    this.modalService.dismissAll();
  }

  reason: any;
  open_reason(content: any, reason: any) {
    this.reason = reason;
    this.open(content);
  }

  tcesend: any;
  tceidsend: any;
  opensend(content: any, tce: any) {
    this.tcesend = tce;
    this.tceidsend = tce.id;

    this.open(content);
  }

  document_src: any;
  openpdf(pdf: any, content: any) {
    this.document_src = pdf;
    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 100);
  }
  dataSourceMessages: MatTableDataSource<any>;

  Messages_list: any[] = [];
  Arr_messages(content: any, id: any) {
    this.messageuser.Gettecknical(id).subscribe((res: any) => {
      this.Messages_list = res;

      setTimeout(() => {
        this.dataSourceMessages = new MatTableDataSource(this.Messages_list);
        this.dataSourceMessages.paginator = this.paginator;
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 100);
    });
  }
  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  y: any = 0;
  generate_pdf(document: any) {
    var doc = new jsPDF();
    doc.setFont('italic');

    doc.setFontSize(20);
    doc.text('Technical Control', 20, 12);

    doc.setFontSize(12);

    doc.text('Application Data', 8, 20);
    doc.text('Branch & Agent', 8, 60);
    doc.text('Trade Company', 8, 100);
    doc.text('Arrival Notice', 113, 100);
    doc.text('Code and Name Country Of Origin', 113, 155);
    doc.text(' Methode (Packing)', 8, 155);
    doc.text('Bl Number', 58, 155);

    doc.text('Item Code', 8, 140);
    doc.text('Item Name', 58, 140);

    doc.text('hs Code', 8, 170);
    doc.text('hs Name', 58, 170);

    doc.text('Quantity', 112, 85);
    doc.text('Quantity Unit Code', 163, 85);
    doc.text('Place and date of Signature', 112, 72);
    doc.text('Date', 112, 60);

    doc.setDrawColor('#058A0D');
    doc.setLineWidth(0.5);

    doc.line(5, 15, 5, 290);
    doc.line(205, 15, 205, 290);
    doc.line(5, 15, 205, 15);
    doc.line(5, 290, 205, 290);

    doc.setFontSize(9);

    doc.line(80, 25, 110, 25);
    doc.line(80, 15, 80, 25);

    doc.text('Application No', 8, 27);
    doc.text('Application Date', 8, 34);
    doc.text('Status', 8, 41);
    doc.text('Clearance Type Code', 8, 48);

    doc.line(110, 15, 110, 180);

    doc.text('Branch Code', 8, 67);
    doc.text('Branch Name', 8, 74);
    doc.text('Agent Company Name', 8, 81);
    doc.text('Agent Staf Name', 8, 87);

    doc.line(80, 65, 110, 65);
    doc.line(80, 55, 80, 65);

    doc.text('Trade Company Code', 8, 107);
    doc.text('Trade Company Id', 8, 114);
    doc.text('Trade Company Name', 8, 121);

    doc.text('Item Shipping Amount.', 113, 20);
    doc.text('Currency Exchange', 165, 20);
    doc.text('Dinar Amount', 113, 35);

    doc.text('Arrival Notice No', 115, 110);
    doc.text('Arrival Notice Date', 115, 120);
    doc.text('Actual Notice Place', 115, 130);
    doc.text('Actual Notice Date', 115, 140);

    doc.line(80, 105, 110, 105);
    doc.line(80, 95, 80, 105);
    doc.line(5, 55, 205, 55);

    doc.line(110, 30, 205, 30);

    doc.line(5, 95, 205, 95);

    doc.line(5, 135, 110, 135);

    doc.line(5, 150, 205, 150);
    doc.line(5, 165, 110, 165);
    doc.line(5, 180, 205, 180);

    doc.line(55, 135, 55, 180);

    doc.line(110, 67, 205, 67);
    doc.line(110, 81, 205, 81);
    doc.line(110, 95, 205, 95);
    doc.setTextColor('#1671b3');
    doc.setFontSize(10);
    if (document.applicationNo != null) {
      doc.text(document.applicationNo + '', 45, 26);
    }
    if (document.applicationDate != null) {
      doc.text(document.applicationDate + '', 45, 33);
    }

    if (document.etat != null) {
      doc.text(document.etat + '', 45, 40);
    }

    if (document.ClearanceTypeCode != null) {
      doc.text(document.ClearanceTypeCode + '', 45, 47);
    }

    if (document.branchCode != null) {
      doc.text(document.branchCode + '', 45, 67);
    }

    if (document.branchName != null) {
      doc.text(document.branchName + '', 45, 74);
    }
    if (document.agentCompanyName != null) {
      doc.text(document.agentCompanyName + '', 45, 81);
    }

    if (document.agentStaffame != null) {
      doc.text(document.agentStaffame + '', 45, 87);
    }

    if (document.tradeCompanyCode != null) {
      doc.text(document.tradeCompanyCode + '', 45, 107);
    }

    if (document.tradeCompanyId != null) {
      doc.text(document.tradeCompanyId + '', 45, 114);
    }
    if (document.tradeCompanyName != null) {
      doc.text(document.tradeCompanyName + '', 45, 121);
    }
    if (document.itemShippingAmount != null) {
      doc.text(document.itemShippingAmount + '', 113, 25);
    }

    if (document.currencyExchange != null) {
      doc.text(document.currencyExchange + '', 165, 25);
    }
    if (document.quantity != null) {
      doc.text(document.quantity + '', 117, 90);
    }

    if (document.quantityUnitCode != null) {
      doc.text(document.quantityUnitCode + '', 170, 90);
    }
    if (document.countryCode != null) {
      doc.text(document.countryCode + '   ' + document.countryName, 120, 165);
    }

    if (document.methodCode != null) {
      doc.text(document.methodCode + ' && ' + document.methodName, 8, 159);
    }
    if (document.blnumber != null) {
      doc.text(document.blnumber + '', 58, 159);
    }
    if (document.ItemCode != null) {
      doc.text(document.ItemCode + '', 8, 145);
    }

    if (document.itemName != null) {
      doc.text(document.itemName + '', 58, 145);
    }
    if (document.hsCode != null) {
      doc.text(document.hsCode + '', 8, 175);
    }

    if (document.hsName != null) {
      doc.text(document.hsName + '', 58, 175);
    }

    if (document.arrivalNoticeNo != null) {
      doc.text(document.arrivalNoticeNo + '', 150, 110);
    }

    if (document.arrivalNoticeDate != null) {
      doc.text(document.arrivalNoticeDate + '', 150, 120);
    }
    if (document.actualArrivalPlace != null) {
      doc.text(document.actualArrivalPlace + '', 150, 130);
    }
    if (document.actualArrivalDate != null) {
      doc.text(document.actualArrivalDate + '', 150, 140);
    }

    doc.save('Technical Control' + document.id + '.pdf');
  }

  Id_org: any;
  TechnicalDocument: any;
  submit(content: any, document: any) {
    this.TechnicalDocument = document;
    this.TechnicalDocument.etat = 'For confirmation';
    this.Id_org = this.TechnicalDocument.invoiceNo;
    this.technicalService
      .submit(this.TechnicalDocument, this.Id_org)
      .subscribe((res: any) => {
        this.open(content);

        let formdata = {
          action: 'SUBMIT',

          idOrgSender: this.document.messages[0].organizationSender.id,
          nomOrgSender: this.document.messages[0].organizationSender.name,
          idOrgReceiver: this.document.messages[0].organizationReceiver.id,
          nomOrgReceiver: this.document.messages[0].organizationReceiver.name,
          idScenario: this.document.id,
        };

        this.notificationApi.post(formdata).subscribe((res: any) => { });
      });
  }

  open_submit(content: any, document: any) {
    // this.Id_org = document.;
    this.TechnicalDocument = document;
    this.open(content);
  }

  private socket1: any;
  web_socket: boolean = false;

  Websocket_technical() {
    var host = 'wss://ws.coincap.io/prices?assets=bitcoin,ethereum,litecoin';

    this.socket1 = new WebSocketSubject(host);

    this.socket1.subscribe(
      (message: any) => { },
      (err: any) => console.log(err)
    );

    this.socket1 = new WebSocket(host);
    this.socket1.onmessage = (e: any) => {
      if (this.id_search == 0) {
        this.GetTechnicalControl();
      }
    };

    this.socket1.onopen = (e: any) => {
      if (this.id_search == 0) {
        this.GetTechnicalControl();
      }
    };

    this.socket1.onclose = (e: any) => {
      if (this.id_search == 0) {
        this.GetTechnicalControl();
      }
    };

    this.socket1.onerror = (e: any) => {
      if (this.id_search == 0) {
        this.GetTechnicalControl();
      }
    };
  }

  attr: any;
  search() {
    this.id_search = 1;
    this.technicalService
      .Search(this.attr, this.valeurcondition)
      .subscribe((res: any) => {
        this.ListTechnical_r = res;
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.ListTechnical_r);
          this.dataSource.paginator = this.paginator;
        }, 100);
      });
  }
  @Input() profil: string;
  premission: any;
  changeaction(event: any) {
    this.userslist = [];
    this.selectetuser = null;
    if (event == undefined) {
      this.userslist = [];
    }

    if (event.id == '1') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SAVE';
    } else if (event.id == '2') {
      this.getUser(this.TeamId, this.premission + 'S');
      this.acions_t = 'SUBMIT';
    }
  }

  dataSourceActions: MatTableDataSource<any>;
  listactions: any[];
  id_folder: any;
  message_user: any;
  reason_assign: any;
  messages_actions: any[] = [];
  message_sender: any;
  message_receiver: any;
  Arr_assign(content: any, id_folder: any, document: any) {
    this.transactiontype = 'Save';
    this.status_sc = false;
    this.id_folder = id_folder;
    let row_message = document.messages.length - 1;
    let status_message = document.messages[row_message].status;
    this.message_user = document.messages[row_message];
    this.id_message = document.messages[row_message].id;

    if (this.message_user.organizationSender != null) {
      this.message_sender = this.message_user.organizationSender.id;
    }

    if (this.message_user.organizationReceiver != null) {
      this.message_receiver = this.message_user.organizationReceiver.id;
    }

    let conditionReceiver =
      document.etat == 'For confirmation' &&
      this.message_receiver == this.id_Org_en;

    let conditionSender =
      document.etat == 'Save' && this.message_sender == this.id_Org_en;

    if (status_message == 'SUCCESS' || status_message == 'INPROGRESS') {
      if (conditionReceiver || conditionSender) {
        this.status_sc = false;
        this.messages_actions = this.message_user.actions;

        if (conditionReceiver) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender.id == this.id_Org_en
          );
        }

        if (conditionSender) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender.id == this.id_Org_en
          );
        }
        let row_actions = this.messages_actions.length;
        if (row_actions > 0) {
          if (this.messages_actions[row_actions - 1].status != 'FINISHED') {
            this.status_sc = true;
            this.reason_assign = this.languet.text_assign_2;
          }
        }

        setTimeout(() => {
          this.dataSourceActions = new MatTableDataSource(
            this.messages_actions
          );
        }, 200);
      } else {
        this.status_sc = true;
        this.reason_assign = this.languet.text_assign_1;
      }
    } else {
      this.status_sc = true;
      this.reason_assign = this.languet.text_assign_1;
    }

    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 500);
  }

  selectetassign: any;

  selectetuser: any;
  Id_user: any;
  acions_t: any;
  userslist: any[];

  changeuser(event: any) {
    this.Id_user = event.id;
  }

  getUser(team: any, sc: any) {
    this.userService.GetAllUsers(team, sc).subscribe((res: any) => {
      this.userslist = res;
      console.log(res);
    });
  }
  Assign() {
    if (this.transactiontype == 'Save') {
      this.actiondata = {
        code: this.acions_t,
        affectedToUser: { id: this.Id_user },
        designation: this.designation,
      };
      this.affecationService
        .assignm(this.actiondata, this.id_folder, 'tc')
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();

          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    } else {
      this.actiondata.code = this.acions_t;
      this.actiondata.affectedToUser.id = this.Id_user;
      this.actiondata.designation = this.designation;

      this.affecationService
        .reassign(this.actiondata, this.id_Action)
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    }
  }
  TeamId: any;
  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
      this.TeamId = res.team.id;
      this.Getorganisation_send(res.id);
    });
  }
  designation: any;
  id_message: any;

  color_active = '#77838e';
  status_sc: boolean = false;
  RoleTeammanager: boolean = false;
  RoleTrading: boolean = false;
  id_Org_en: any;

  role_organisation: any;
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.role_organisation = res['profil'];
      this.id_Org_en = res.id;
      this.GetTechnicalControl();
    });
  }

  transactiontype = 'Save';
  id_Action: any;
  action: any;
  useraction: any;
  actiondata: any;

  reassign(action: any) {
    this.transactiontype = 'Update';
    this.action = action;
    this.actiondata = action;
    this.id_Action = action.id;
    this.useraction = action.affectedToUser;
    this.selectetuser = this.useraction.userName;
    this.Id_user = this.selectetuser.id;
    this.selectetassign = this.action.code;
    this.designation = this.action.designation;
    this.status_sc = false;
  }
}
