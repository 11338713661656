<div [ngStyle]="{ direction: direction }">
  <mat-tab-group
    mat-align-tabs="center"
    style="width: 100%; min-height: 600px; background-color: #fdfdfd"
    (selectedTabChange)="changesc($event)"
    [selectedIndex]="selectedIndex"
  >
    <mat-tab *ngIf="Custom_etat || Team_Manager" label="{{ languef.cd }}">
      <app-liste [profil]="profil"></app-liste>
    </mat-tab>
    <mat-tab *ngIf="Tecnhical_etat || Team_Manager" label="{{ languef.tc }}">
      <app-liste-t [profil]="profil"></app-liste-t>
    </mat-tab>
  </mat-tab-group>
</div>
