<div>
  <mat-tab-group
    mat-align-tabs="center"
    style="width: 100%; min-height: 600px; background-color: #ffffff"
    (selectedTabChange)="changesc($event)"
    [selectedIndex]="selectedIndex"
  >
    <mat-tab
      *ngIf="
        (ShippingArrival_etat || Team_Manager) &&
        (profil == 'Shipping_agent' || profil == 'Port')
      "
      label="{{ languef.sa }}"
      origin="sa"
    >
      <app-liste ></app-liste>
    </mat-tab>
    <mat-tab
      *ngIf="
        (Shipping_Departure_etat || Team_Manager) &&
        (profil == 'Shipping_agent' || profil == 'Port')
      "
      label="{{ languef.sdp }}"
      id="sdp"
    >
      <app-liste_depart></app-liste_depart>
    </mat-tab>
    <mat-tab
      *ngIf="
        (Ship_Docking_etat || Team_Manager) &&
        (profil == 'Shipping_agent' || profil == 'Port')
      "
      label="{{ languef.sd }}"
    >
      <app-liste-shipdocking></app-liste-shipdocking>
    </mat-tab>
    <mat-tab
      *ngIf="
        (Cargo_Manifest_etat || Team_Manager) &&
        (profil == 'Shipping_agent' || profil == 'Port' || profil == 'DOUANES')
      "
      label="{{ languef.cm }}"
    >
      <app-liste-cargo></app-liste-cargo>
    </mat-tab>
  </mat-tab-group>
</div>
