import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { MatRadioModule } from '@angular/material/radio';

import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

import { PageAccueilComponent } from './page/page-accueil/page-accueil.component';
import { AuthGuard } from 'src/Services/Guard/auth.guard';
import { LettreCreditModule } from './lettre_de_credit/lettre_de_credit.module';
import { Shipping_garanteModule } from './Shipping_garante/Shipping_garante.module';
import { CertificatOrigintModule } from './Certificat_origin/certificat_origin.module';
import { TradeGuard } from 'src/Services/Roles/E-trade/trade.guard';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ListeComponent } from './Certificat_origin/liste/liste.component';

@NgModule({
  declarations: [PageAccueilComponent, DashboardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTabsModule,
    NgxSkeletonLoaderModule,
    LettreCreditModule,
    MatRadioModule,
    CertificatOrigintModule,
    Shipping_garanteModule,

    RouterModule.forChild([
      {
        path: 'liste',
        component: PageAccueilComponent,
        canActivate: [AuthGuard, TradeGuard],
      },
    ]),
  ],
  exports: [DashboardComponent, PageAccueilComponent, ListeComponent],
  providers: [DatePipe],
})
export class EtradeModule { }
