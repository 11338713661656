<div class="containerA">
  <div class="navbar">
    <div class="logo">
      <img src="../../../../assets/logo_dark.png" width="202px" />
    </div>
    <div class="second_column">
      <div class="itemflex">
        <a href="" class="itemflex" style="width: 120px;color: #111111;">{{langueh.logistic}}</a>
        <ul class="dropdown-cnt">
          <!-- <li><a href="#">{{langueh.sg}}</a></li> -->
          <li><a href="#">Shipping Arrival</a></li>
          <li><a href="#">Ship Docking</a></li>
          <li><a href="#">Shipping Departure</a></li>
          <li><a href="#">Cargo Manifest</a></li>
        </ul>
      </div>
      <div class="itemflex">
        <a href="" class="itemflex" style="width: 120px;color: #111111;">{{langueh.trade}}</a>
        <ul class="dropdown-cnt">
          <li><a href="#">{{langueh.lc}}</a></li>
          <li><a href="#">{{langueh.co}}</a></li>
          <li><a href="#">{{langueh.sg}}</a></li>
        </ul>
      </div>
      
      <div class="itemflex" >
        <a  href="" style="width: 120px;color: #111111;">{{langueh.customs}}</a>
        <ul class="dropdown-cnt">
          <li><a href="#">{{langueh.cd}}</a></li>
          <li><a href="#">{{langueh.tc}}</a></li>
        </ul>
      </div>
      
    </div>
    <div class="last_column">
        <div
        style="padding-right: 2%; padding-left: 2%; cursor: pointer; display: flex;justify-content: center;align-items: center;"
      >
        <a
          class="dropdown"
          style="display: flex; margin-left: 5px; margin-top: 6%"
        >
          <div>
            <div style="display: flex">
              <img src="../../../../assets/icons/ICONS.png"/>
              <span
                style="
                  padding-left: 7%;
                  padding-right: 7%;
                  font-size: 16px;
                  color: #111111;
                "
                >
                {{ langue_nom }}
              </span>
            </div>
            <div
              class="dropdown-content1"
              [ngStyle]="{ direction: direction }"
            >
              <a
                *ngIf="langue == 'AR' || langue == 'FR'"
                style="
                  display: flex;
                  border-bottom: solid 1px #1671B3;
                "
                (click)="changelangue('EN')"
              >
                <div></div>
                <div style="margin-left: 5px; display: flex">
                  <img
                    src="./../../../assets/svg-country-flags/svg/gb-eng.svg"
                    width="25px"
                  />
                  <span style="font-size: 15px; padding: 8%">{{
                    langueh.english
                  }}</span>
                </div>
              </a>
              <a
                *ngIf="langue == 'EN' || langue == 'FR'"
                (click)="changelangue('AR')"
                style="display: flex"
              >
                <div></div>
                <div style="margin-left: 5px; display: flex">
                  <img
                    src="./../../../assets/svg-country-flags/svg/tn.svg"
                    width="25px"
                  />
                  <span style="font-size: 15px; padding: 8%">{{
                    langueh.arabic
                  }}</span>
                </div>
              </a>
              <a
                *ngIf="langue == 'EN' || langue == 'AR'"
                (click)="changelangue('FR')"
                style="display: flex"
              >
                <div></div>
                <div style="margin-left: 5px; display: flex">
                  <img
                    src="./../../../assets/svg-country-flags/svg/fr.svg"
                    width="30px"
                  />
                  <span style="font-size: 15px; padding: 8%">{{
                    langueh.france
                  }}</span>
                </div>
              </a>
            </div>
          </div>
          <div style="padding-right: 3%; padding-left: 3%">
            <img  src="../../../../assets/icons/explore.png"/>
          </div>
        </a>
      </div>
      <div class="buttonG">
        <input type="button" [value]="langueh.login" class="login_button"/>
        <input type="button" [value]="langueh.signup" class="sign_up_button"/>
      </div>
    </div>
  </div>
  <div class="containerbg">
    <div class="auth_container">
      <div class="left_form">
        <h3 class="title">{{languef.titreLog}}</h3>
        <form [formGroup]="loginForm" style="width: 100%;">
          <div class="user_name" >
            <img src="../../../../assets/icons/username.png" class="icon_input"/>
            <input
            style="color: #198D9C"
            formControlName="userName"
            required
            [placeholder]="languef.username"
            class="input"
            type="text"
            />
          </div>
          <div class="password">
            <img src="../../../../assets/icons/password.png" class="icon_input"/>
            <input
            style="color: #198D9C"
              formControlName="password"
              class="input"
              type="password"
              [placeholder]="languef.password"
              required
          />
          </div>
            <a href="http://141.95.150.139:5001/forgot-pwd"  style="margin-left: 10%;" class="forgetPass">
             {{languef.forgetPass}}
            </a>
          <div class="button">
            <button
            type="submit"
            [disabled]="loginForm.invalid"
            (click)="login(modalmessage)"
            class="login_btn"
          >
            {{ languef.btnlog }}
          </button>
          </div>
        </form>
      </div>
      <div class="right_img"></div>
    </div>
  </div>
</div>
<ng-template #modalmessage>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <label
          style="
            width: 310px;
            font-size: 20px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ messagelogin }}</label
        >
      </div>
    </div>
  </div>
</ng-template>