import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';


import { MatTabsModule } from '@angular/material/tabs';


import { PageAccueilComponent } from './page/page-accueil/page-accueil.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';

import { CustomsModule } from './Customs/Customs.module';


import { TechnicalModule } from './Technical/Technical.module';
import { AuthGuard } from 'src/Services/Guard/auth.guard';
import { TradingGuard } from 'src/Services/Roles/Trading/trading.guard';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [PageAccueilComponent, DashboardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTabsModule,
    CustomsModule,
    TechnicalModule,
    NgxSkeletonLoaderModule,
    RouterModule.forChild([
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, TradingGuard],
      },
      {
        path: 'liste',
        component: PageAccueilComponent,
        canActivate: [AuthGuard, TradingGuard],
      },
    ]),
  ],
  exports: [DashboardComponent, PageAccueilComponent],
  providers: [DatePipe],
})
export class EcustomsModule { }
