import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import Langue from '../../../../../assets/langue.json';
import { WebSocketSubject } from 'rxjs/webSocket';
import { CertificatService } from '../Services/certificat.service';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { FormGroup } from '@angular/forms';
import { UserService } from 'src/Services/User/user.service';
import { AttachmentService } from 'src/Services/AttchementTrade/attachment.service';
import { AffectationService } from 'src/Services/Affectation/affectation.service';
import { NotifierService } from 'angular-notifier';
import { NotificationService } from 'src/Services/Notification/notification.service';

@Component({
  selector: 'app-liste-certificat',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css'],
})
export class ListeComponent implements OnInit {
  index: number = 0;
  displayedColumns: string[] = [
    'select',
    'id',
    'applicationdate',
    'invoicenumber',
    'invoicedate',
    'chamberofcommerce',
    'consignee',
    'totalvalue',
    'assign',
    'etat',
    'action',
  ];

  displayedColumnsActions: string[] = [
    'id',
    'status',
    'startDate',
    'affectedto',
    'action',
  ];
  itemlist: any[];

  onchangedatestart(event: any) {
    this.startdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  id_search: any = 0;
  typerecherche: any;
  date_disabled: boolean = true;
  changedate(event: any) {
    if (event != undefined) {
      this.date_disabled = false;
      this.typerecherche = event.code;
    } else {
      this.id_search = 0;
      this.startDate = '';
      this.endDate = '';
      this.date_disabled = true;
      this.GetCertificat(this.id_Org_en);
    }
  }
  startdate: any;
  enddate: any;
  @Input() profil: string;
  selecteddate: any;
  startDate: any;
  endDate: any;
  selectedstatus: any;
  statuslist: any[];
  itemslist: any[];
  selectedcondition: any;
  valeurcondition: any;
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.id_search = 0;
      this.valeurcondition = '';
      this.GetCertificat(this.id_Org_en);
    } else {
      this.attr = event.code;
    }
  }
  etat: any;
  changestatus(event: any) {
    if (event != undefined) {
      this.etat = event.code;
      this.search_status();
    } else {
      this.id_search = 0;

      this.GetCertificat(this.id_Org_en);
    }
  }

  search_status() {
    this.id_search = 3;

    if (this.etat == 'INPROGRESS' || this.etat == 'SUBMITTED') {
      this.ListCertificat_f = this.ListCertificat_t.filter(
        (e) => e.status == this.etat
      );
    } else {
      this.ListCertificat_f = this.ListCertificat_t.filter(
        (e) => e.approvalStatus == this.etat
      );
    }

    this.dataSource = new MatTableDataSource(this.ListCertificat_f);
    this.dataSource.paginator = this.paginator;
  }
  onchangedateend(event: any) {
    this.enddate = this.datePipe.transform(event, 'yyyy-MM-dd');

    this.serach_date();
  }

  serach_date() {
    this.id_search = 2;
    if (this.typerecherche == 'applicationDate') {
      this.ListCertificat_f = this.ListCertificat_t.filter(
        (e) =>
          e.applicationDate >= this.startdate &&
          e.applicationDate <= this.enddate
      );

      this.dataSource = new MatTableDataSource(this.ListCertificat_t);
      this.dataSource.paginator = this.paginator;
    } else if (this.typerecherche == 'invoiceDate') {
      this.ListCertificat_f = this.ListCertificat_t.filter(
        (e) => e.invoiceDate >= this.startdate && e.invoiceDate <= this.enddate
      );

      this.dataSource = new MatTableDataSource(this.ListCertificat_f);
      this.dataSource.paginator = this.paginator;
    } else {
      this.GetCertificat(this.id_Org_en);
    }
  }

  dataSource: MatTableDataSource<any>;

  displayedColumnsP: string[] = ['file', 'actions'];
  dataSourcep: MatTableDataSource<any>;
  ListCustoms: any[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;

  constructor(
    private titleService: Title,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private affecationService: AffectationService,
    private attchementsApi: AttachmentService,
    public sanitizer: DomSanitizer,
    private certificatService: CertificatService,
    private organisationService: OrganisationService,
    private userService: UserService,
    private notifier: NotifierService,
    private notificationApi: NotificationService
  ) {
    this.dataSource = new MatTableDataSource(this.ListCertificat_f);

    this.dataSource = new MatTableDataSource(this.Messages_list);

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
      this.languea = this.langue_list[0]['AR'].CO;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
      this.languea = this.langue_list[0]['EN'].CO;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
      this.languea = this.langue_list[0]['FR'].CO;
    }
    this.titleService.setTitle(this.languet.entete);

    this.statuslist = [
      { id: 1, name: this.languet.save, code: 'INPROGRESS' },
      { id: 2, name: this.languet.forconfirmation, code: 'SUBMITTED' },
      { id: 3, name: this.languet.accept, code: 'ACCEPT' },
      { id: 4, name: this.languet.rejectd, code: 'REJECT' },
    ];

    this.itemlist = [
      {
        id: 1,
        name: this.languea.applicationdate,
        code: 'applicationDate',
        code1: 'startDate',
      },
      {
        id: 1,
        name: this.languea.invoicedate,
        code: 'invoiceDate',
        code1: 'invoiceDate',
      },
    ];

    this.itemslist = [
      {
        id: 4,
        name: this.languea.invoicenumber,
        code: 'invoiceNumber',
      },
      {
        id: 4,
        name: this.languea.invoicedate,
        code: 'invoiceDate',
      },
      {
        id: 4,
        name: this.languea.chamberofcommerce,
        code: 'chamberofcommerce',
      },
      {
        id: 4,
        name: this.languea.consigneename,
        code: 'consigneeName',
      },
      {
        id: 4,
        name: this.languea.totalvalue,
        code: 'totalValue',
      },
      {
        id: 4,
        name: this.languef.affectedto,
        code: 'affectedto',
      },
    ];

    this.actionlist = [
      { id: '1', name: this.languef.save },
      { id: '2', name: this.languef.submit },
    ];

    this.id_Org_en = localStorage.getItem('idorg');

    this.RoleTeammanager = this.userService.ValidRole('Team manager');
    this.tradingcompany = this.userService.ValidRole('Trading Company');
  }

  displayedColumnsItems: string[] = [
    'marksandnumberofpackage',
    'numberandkindofpackage',
    'netweight',
    'grossweight',
    'quantity',
    'proceedsforeign',
    'currencyvalue',
    'actions',
  ];

  displayedColumnsElement: string[] = ['id', 'name', 'quantity', 'value'];
  dataSourceItems: MatTableDataSource<any>;
  dataSourceElement: MatTableDataSource<any>;

  list_delete: any[] = [];
  actionlist: any[];
  Arr_remove() {
    this.modalService.dismissAll();
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));

    for (let Certificat of this.list_delete) {
      let Certificat_d = this.ListCertificat_f.find(
        (x) => x.id == Certificat.id
      );

      if (Certificat_d.status == 'INPROGRESS') {
        this.certificatService
          .delete(Certificat_d.id)
          .subscribe((res: any) => { });
      }
    }
    setTimeout(() => {
      this.GetCertificat(this.id_Org_en);
      this.ClearAll();
    }, 300);
  }
  document: any;
  Arr_view(content: any, row: any) {
    this.document = row;
    this.Article_DATA = this.document.items;
    setTimeout(() => {
      this.getAttchements(this.document.id);
      this.dataSourceItems = new MatTableDataSource(this.Article_DATA);
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 300);
  }

  Elements_DATA: any;
  document_item: any;
  Arr_view_Elements(content: any, row: any) {
    this.document_item = row;
    this.Elements_DATA = this.document_item.elements;
    setTimeout(() => {
      this.dataSourceElement = new MatTableDataSource(this.Elements_DATA);
      this.open(content);
    }, 300);
  }

  open(content: any) {
    this.modalService.open(content);
  }
  Arr_update() { }

  ListCertificat_f: any[] = [];
  ListCertificat_1: any[] = [];
  ListCertificat_t: any[] = [];
  GetCertificat(id: any) {
    if (this.RoleTeammanager) {

      this.certificatService
        .GetByIdorg(this.id_Org_en)
        .subscribe((res: any) => {
          setTimeout(() => {
            this.remplir_list(res);
          }, 300);


        });
    } else {
      this.certificatService.GetByIdUser(this.UserId).subscribe((res: any) => {
        this.remplir_list(res);
      });
    }
  }

  remplir_list(list: any) {
    this.ListCertificat_1 = list;

    let resultB = this.ListCertificat_1.filter(
      (elm) =>
        !this.ListCertificat_f.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );
    let resultC = this.ListCertificat_f.filter(
      (elm) =>
        !this.ListCertificat_1.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    if (resultB.length > 0 || resultC.length > 0) {
      this.ListCertificat_f = this.ListCertificat_1;
      this.ListCertificat_t = this.ListCertificat_1;
      this.ListCertificat_1 = [];
      if (this.selection.selected.length == 0) {


        this.dataSource = new MatTableDataSource(this.ListCertificat_f);
        this.dataSource.paginator = this.paginator;
      }
    }
  }
  Article_DATA: any[] = [];

  dataSourceArticles: MatTableDataSource<any>;
  ngOnInit(): void {
    this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
    this.GetIdUser();
    // this.Websocket_Certificat();

    if (this.profil == 'Chamber_of_Commerce') {
      this.premission = 'CO_A2.1_LE';
      this.actionlist = [{ id: '2', name: this.languef.submit }];
    } else {
      this.premission = 'CO_A1.1_LE';
      this.actionlist = [
        // { id: '1', name: this.languef.save },
        { id: '2', name: this.languef.submit },
      ];
    }
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  ClearAll() {
    this.selection.clear();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  list_print: any[] = [];
  printdoc() {
    this.list_print = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let tce of this.list_print) {
      let titre = this.ListCertificat_f.find((x) => x.id == tce.id);
      this.generate_pdf1(titre, titre.attachments);
    }

    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }

  dimision() {
    this.modalService.dismissAll();
  }

  reason: any;
  open_reason(content: any, reason: any) {
    this.reason = reason;
    this.open(content);
  }

  tcesend: any;
  tceidsend: any;
  opensend(content: any, tce: any) {
    this.tcesend = tce;
    this.tceidsend = tce.id;

    this.open(content);
  }

  document_src: any;
  openpdf(pdf: any, content: any) {
    this.document_src = pdf;
    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 100);
  }
  dataSourceMessages: MatTableDataSource<any>;

  Messages_list: any[] = [];
  Arr_messages(content: any, messages: any) {
    this.Messages_list = messages;

    setTimeout(() => {
      this.dataSourceMessages = new MatTableDataSource(this.Messages_list);
      this.dataSourceMessages.paginator = this.paginator;
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 100);
  }
  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  y: any = 0;

  Id_org: any;
  CertificatDocument: any;
  submit(content: any, row: any) {
    this.certificatService.submit(row).subscribe((res: any) => {
      this.open(content);
      this.document = row;
      let formdata = {
        action: 'SUBMIT',

        idOrgSender: this.document.messages[0].organizationSender.id,
        nomOrgSender: this.document.messages[0].organizationSender.name,
        idOrgReceiver: this.document.messages[0].organizationReceiver.id,
        nomOrgReceiver: this.document.messages[0].organizationReceiver.name,
        idScenario: this.document.id,
      };

      this.notificationApi.post(formdata).subscribe((res: any) => { });
    });
  }

  open_submit(content: any, document: any) {
    // this.Id_org = document.;
    this.CertificatDocument = document;
    this.open(content);
  }

  private socket1: any;
  web_socket: boolean = false;

  Websocket_Certificat() {
    var host = 'wss://ws.coincap.io/prices?assets=bitcoin,ethereum,litecoin';

    this.socket1 = new WebSocketSubject(host);

    this.socket1.subscribe(
      (message: any) => { },
      (err: any) => console.log(err)
    );

    this.socket1 = new WebSocket(host);
    this.socket1.onmessage = (e: any) => {
      if (this.id_search == 0) {
        this.GetCertificat(this.id_Org_en);
      }
    };
  }
  id_Org_en: any;
  UserId: any;
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.id_Org_en = res.id;

      this.GetCertificat(this.id_Org_en);
    });
  }

  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
      this.UserId = res.id;
      this.TeamId = res.team.id;
      this.Getorganisation_send(this.UserId);
    });
  }

  Pieces_DATA: any[] = [];
  FormPieces: FormGroup;
  displayedColumnsPieces: string[] = ['name', 'file', 'action'];
  dataSourcePieces: MatTableDataSource<any>;

  attr: any;
  search() {
    this.id_search = 1;
    this.certificatService
      .Search(this.attr, this.valeurcondition)
      .subscribe((res: any) => {
        this.ListCertificat_f = res;
        this.dataSource = new MatTableDataSource(this.ListCertificat_f);
        this.dataSource.paginator = this.paginator;
      });
  }

  openFile(base64String: string, contentType: string | null | undefined): void {
    // this.dataUtils.openFile(base64String, contentType);
    window.open(base64String, '_blank');
  }

  file_source: any;
  type_source: any;
  viewPDF(content: any, file: any) {
    this.file_source = file.attachment;

    if (file.attachmentContentType.indexOf('image') != -1) {
      this.type_source = 'image';
    } else if (file.attachmentContentType.indexOf('pdf') != -1) {
      this.type_source = 'pdf';
    } else {
      this.type_source = 'no';
    }

    if (this.type_source != 'no') {
      setTimeout(() => {
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 400);
    }
  }

  generate_pdf1(document: any, attachmentsName: string[]): void {
    // eslint-disable-next-line no-console

    const doc = new jsPDF();
    const img = new Image();
    img.src = './../../../../assets/chambre_c.png';
    doc.addImage(img, 'png', 90, 3, 25, 25);
    doc.setFontSize(13);
    doc.setFont('bold');
    doc.text('Tunisian Republic', 155, 15);
    doc.setFontSize(10);
    doc.text('Tunisian Republic', 20, 14);
    doc.text('CHAMBER OF COMMERCE', 11, 18);
    doc.text('INDUSTRY & AGRICULTURE', 10, 22);
    doc.text('Tunis', 24, 26);
    doc.setFontSize(13);
    doc.setDrawColor('#000');
    doc.setFontSize(22);
    doc.setFont('bold');
    doc.text('Certificate of Origin', 70, 35);
    doc.setFontSize(8);
    doc.setLineWidth(0.4);
    // doc.text('_app number', 17, 37);
    doc.text(String(document.id ?? ''), 17, 37);
    // doc.text('_app date', 175, 38);
    const date = document.applicationDate;

    if (document.applicationDate != null) {
      doc.text(String(date), 175, 38);
    }

    doc.line(15, 40, 15, 290);
    doc.line(15, 40, 195, 40);
    doc.line(195, 40, 195, 290);
    doc.line(15, 290, 195, 290);
    doc.line(105, 40, 105, 130);
    doc.line(105, 55, 195, 55);
    doc.line(15, 70, 195, 70);
    doc.line(105, 85, 195, 85);
    doc.line(15, 100, 195, 100);
    //doc.line(60, 55, 60, 70);
    doc.line(15, 130, 195, 130);
    doc.line(15, 145, 195, 145);
    doc.line(30, 130, 30, 270);
    doc.line(72, 130, 72, 270);
    doc.line(135, 130, 135, 270);
    doc.line(165, 130, 165, 270);
    doc.line(149, 136, 149, 270);
    doc.line(135, 136, 165, 136);
    //doc.line(60, 85, 60, 100);
    doc.line(15, 117, 105, 117);
    doc.line(60, 117, 60, 130);
    doc.line(15, 270, 195, 270);
    doc.setFontSize(10);
    doc.setTextColor('#0000FF');
    doc.setFont('italic');
    doc.text("1- Exporter's name", 20, 44);
    doc.text('Adresse', 20, 60);
    doc.text("2- Consignee's name", 20, 75);
    doc.text('Adresse', 20, 90);
    doc.text('3- Invoice Number', 110, 45);
    doc.text('4- Invoice Date', 110, 60);
    doc.text('5- Has cumulation applied with other countries ?', 110, 75);
    doc.text('6- Country Of Origin', 110, 90);
    doc.text('7- Means Of  Transport', 20, 104);
    doc.text('8- Producer Name', 20, 121);
    doc.text('9- Producer Address', 65, 121);
    doc.text('10- Country Of Manifacture', 110, 104);
    doc.text('17- Total Value :', 20, 280);
    doc.setFontSize(8);
    doc.text('11- Item no ', 16, 134);
    doc.text('12- Marks and number ', 31, 134);
    doc.text('       of package', 27, 139);
    doc.text('13- Number and kind of package', 76, 134);
    doc.text('(description of goods)', 85, 139);
    doc.text('Weight', 145, 134);
    doc.text('14- Gross ', 136, 140);
    doc.text('15- Net ', 150, 140);
    doc.text('16- Currency Value ', 166, 134);
    doc.setTextColor('#000');
    doc.setFontSize(8);
    doc.text(String(document.consigneeName ?? ''), 35, 80);

    // doc.text(String(document.messages?.at(0)?.organizationReceiver?.name), 35, 80);
    if (document.invoiceDate != null) {
      doc.text(String(document.invoiceDate), 115, 65);
    }
    doc.text(
      String(document.messages?.at(0)?.organizationSender?.name ?? ''),
      35,
      50
    );
    doc.text(String(document.invoiceNumber ?? ''), 130, 50);
    doc.text(String(document.countryOfOrigin ?? ''), 130, 95);
    doc.text(String(document.meansTransportAndRoute ?? ''), 35, 110);
    doc.text(String(document.cumulation ?? ''), 180, 75);
    if (document.cumulationCountries != null) {
      doc.setTextColor('#0000FF');
      doc.setFont('italic');
      doc.text('Cumulation Countries: ', 115, 81);
      doc.setTextColor('#000');
      doc.text(String(document.cumulationCountries ?? ''), 141, 81);
    }
    //doc.text('...................................................................', 115, 83);
    doc.text(
      String(document.messages?.at(0)?.organizationSender?.address ?? ''),
      21,
      65
    );
    doc.text(String(document.consigneeAddress ?? ''), 21, 95);

    // doc.text(String(document.messages?.at(0)?.organizationReceiver?.address ?? ''), 21, 95);
    doc.text(String(document.producerName ?? ''), 21, 127);
    doc.text(String(document.producerAddress ?? ''), 61, 127);
    doc.text(String(document.totalValue ?? ''), 70, 280);
    doc.text(String(document.countryOfManifacture ?? ''), 120, 109);
    let z = 150;
    for (const item of document.items) {
      if (z < 270) {
        if (item.id != null) {
          doc.text(String(item.id), 17, z);
        }
        if (item.marksAndNumberOfPackage != null) {
          doc.text(String(item.marksAndNumberOfPackage), 32, z);
        }
        if (item.numberAndKindOfPackage != null) {
          doc.text(String(item.numberAndKindOfPackage), 74, z);
        }
        if (item.descriptionOfGoods != null) {
          const description = String(item.descriptionOfGoods);
          // eslint-disable-next-line no-console

          // if (description.length < 56) {
          //   doc.text('(' + String(item.descriptionOfGoods) + ')', 74, z + 5);
          // } else {
          //   const description2 = description.substring(0, 43);
          //   doc.text('(' + String(description2 + ' ...)'), 74, z + 5);
          // }
          if (doc.getTextWidth(description) < 59) {
            doc.text('(' + String(item.descriptionOfGoods) + ')', 74, z + 5);
          } else {
            // const description2 = description.substring(0, 22);
            let i = 22;
            while (doc.getTextWidth(description.substring(0, i)) < 54) {
              i++;
            }
            const description2 = description.substring(0, i);
            // eslint-disable-next-line no-console

            // eslint-disable-next-line
            doc.text('(' + String(description2 + ' ...)'), 74, z + 5);
          }
        }
        if (item.grossWeight != null) {
          doc.text(String(item.grossWeight), 137, z);
        }
        if (item.netWeight != null) {
          doc.text(String(item.netWeight), 151, z);
        }
        if (item.currencyValue != null) {
          doc.text(String(item.currencyValue), 167, z);
        }
      }
      z = z + 10;
    }
    doc.addPage('', 'p');
    doc.line(15, 10, 15, 290);
    doc.line(15, 10, 195, 10);
    doc.line(195, 10, 195, 290);
    doc.line(15, 290, 195, 290);
    doc.line(15, 25, 195, 25);
    doc.line(15, 35, 195, 35);
    doc.line(100, 25, 100, 180);
    doc.line(142, 25, 142, 180);
    doc.line(195, 25, 195, 180);
    doc.line(105, 180, 105, 290);
    doc.line(15, 150, 100, 150);
    doc.line(15, 160, 195, 160);
    doc.line(15, 170, 195, 170);
    doc.line(15, 180, 195, 180);
    doc.setFontSize(10);
    doc.setTextColor('#0000FF');
    doc.setFontSize(12);
    doc.text('18- Statement of Production elements', 67, 20);
    doc.setFontSize(10);
    doc.text('19- Foreign cost elements', 18, 30);
    doc.text('20- Quantity', 103, 30);
    doc.text('21- Value', 145, 30);
    doc.text('22- Procedes Foreign ', 18, 155);
    doc.text('23- Total', 18, 165);
    doc.text('24- Final Cost of Porduction ', 18, 175);
    doc.text('25- Declartaion by the exporter ', 20, 185);
    doc.text('26- Certification ', 110, 185);
    doc.setFontSize(8);
    doc.setTextColor('#000');
    //doc.text('_Procedes Foreign ', 55, 155);
    doc.text('....', 55, 155);
    //  doc.text('_T1 ', 115, 165);
    //  doc.text('_T2 ', 150, 165);
    // doc.text('_F1 ', 115, 175);
    // doc.text('_F2 ', 150, 175);
    doc.text(
      String(document?.items?.at(0)?.finalCostOfProduction ?? ''),
      150,
      175
    );
    doc.text(
      'The undersigned hereby declares that the above details and',
      20,
      190
    );
    doc.text(
      'statements are correct that all the goods were produces',
      20,
      195
    );
    doc.text('_country', 20, 210);
    doc.text(
      '........................................................',
      20,
      212
    );
    doc.text('_Importing Country', 20, 240);
    doc.text(
      '........................................................',
      20,
      242
    );
    doc.text('_Name of exporting', 20, 255);
    doc.text(
      '........................................................',
      20,
      257
    );
    doc.text('_address', 20, 270);
    doc.text(
      '........................................................',
      20,
      272
    );
    doc.text('_date', 20, 285);
    doc.text(
      '........................................................',
      20,
      287
    );
    doc.text(
      'that they comply with the origin requirements specified for these goods',
      20,
      220
    );
    doc.text(
      'in the ASEAN Trade in Goods Agreements for the goods exported to',
      20,
      225
    );
    doc.text('The authorized official hereby acknowledges the', 110, 190);
    doc.text(
      'specified country of origin of the merchandise listed above',
      110,
      195
    );
    doc.text('_signature Authorizing', 110, 210);
    doc.text(
      '........................................................',
      110,
      212
    );
    doc.text("_Shippers's Signature", 110, 225);
    doc.text(
      '........................................................',
      110,
      227
    );
    doc.text('_City', 110, 240);
    doc.text(
      '........................................................',
      110,
      242
    );
    let f = 40;
    let totalvalue = 0;
    if (document.items != null && document.items!.length > 0) {
      for (const item of document.items) {
        if (f < 150) {
          if (item.elements != null && item.elements!.length > 0) {
            for (const element of item.elements) {
              if (f < 180) {
                if (element.name != null) {
                  doc.text(String(element.name), 17, f);
                }
                if (element.quantity != null) {
                  doc.text(String(element.quantity), 115, f);
                }
                if (element.value != null) {
                  doc.text(String(element.value), 150, f);
                }
              }
              f = f + 7;
            }
          }
        }
        totalvalue += item.totalValue;
      }
    }
    doc.text(String(totalvalue), 150, 165);
    doc.setFontSize(10);
    doc.setTextColor('#0000FF');
    doc.text('Country', 20, 205);
    doc.text('Importing Country', 20, 235);
    doc.text('Name Of Exporting Agent', 20, 250);
    doc.text('Address', 20, 265);
    doc.text('Date', 20, 280);
    //2
    doc.text('signature of Authorizing Official', 110, 205);
    doc.text("Shippers's Signature", 110, 220);
    doc.text('City', 110, 235);
    doc.text('27- Attachments', 110, 250);
    let r = 255;
    doc.setFontSize(8);
    doc.setTextColor('#000');
    if (attachmentsName.length > 0) {
      for (const attach of attachmentsName) {
        if (r < 286) {
          // eslint-disable-next-line
          // if (attach != null) {
          doc.text('* ' + String(attach), 110, r);
          // }
        }
        r = r + 5;
      }
    }
    doc.save('certificateOrigin_' + String(document.id) + '.pdf');
  }

  getAttchements(id: any) {
    this.attchementsApi.findByCO(id).subscribe((res: any) => {
      this.Pieces_DATA = res.body;

      setTimeout(() => {
        this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
      }, 300);
    });
  }

  dataSourceActions: MatTableDataSource<any>;

  id_folder: any;
  messageslist: any;
  reason_assign: any;

  selectetassign: any;

  message_user: any;
  messages_actions: any[] = [];
  message_sender: any;
  message_receiver: any;
  Arr_assign(content: any, id_folder: any, document: any) {
    this.transactiontype = 'Save';
    this.status_sc = false;
    this.id_folder = id_folder;
    let row_message = document.messages.length - 1;
    let status_message = document.messages[row_message].status;
    this.message_user = document.messages[row_message];
    this.id_message = document.messages[row_message].id;

    if (this.message_user.organizationSender != null) {
      this.message_sender = this.message_user.organizationSender.id;
    }

    if (this.message_user.organizationReceiver != null) {
      this.message_receiver = this.message_user.organizationReceiver.id;
    }

    let conditionReceiver =
      document.status == 'SUBMITTED' && this.message_receiver == this.id_Org_en;

    let conditionSender =
      document.status == 'INPROGRESS' && this.message_sender == this.id_Org_en;

    if (status_message == 'SUCCESS' || status_message == 'INPROGRESS') {
      if (conditionReceiver || conditionSender) {
        this.status_sc = false;
        this.messages_actions = this.message_user.actions;

        if (conditionReceiver) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender.id == this.id_Org_en
          );
        }

        if (conditionSender) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender.id == this.id_Org_en
          );
        }
        let row_actions = this.messages_actions.length;

        if (row_actions > 0) {
          this.messages_actions = this.messages_actions.sort(
            (a, b) => a.id - b.id
          );

          if (this.messages_actions[row_actions - 1].status != 'FINISHED') {
            this.status_sc = true;
            this.reason_assign = this.languet.text_assign_2;
          }
        }

        setTimeout(() => {
          this.dataSourceActions = new MatTableDataSource(
            this.messages_actions
          );
        }, 200);
      } else {
        this.status_sc = true;
        this.reason_assign = this.languet.text_assign_1;
      }
    } else {
      this.status_sc = true;
      this.reason_assign = this.languet.text_assign_1;
    }

    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 500);
  }

  selectetuser: any;
  Id_user: any;
  acions_t: any;
  userslist: any[];

  changeuser(event: any) {
    this.Id_user = event.id;
  }

  getUser(team: any, sc: any) {
    this.userService.GetAllUsers(team, sc).subscribe((res: any) => {
      this.userslist = res;
    });
  }
  Assign() {
    if (this.transactiontype == 'Save') {
      this.actiondata = {
        code: this.acions_t,
        affectedToUser: { id: this.Id_user },
        designation: this.designation,
      };

      this.affecationService
        .assignm(this.actiondata, this.id_folder, 'co')
        .subscribe((res: any) => {
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
        });
    } else {
      this.actiondata.code = this.acions_t;
      this.actiondata.affectedToUser.id = this.Id_user;
      this.actiondata.designation = this.designation;

      this.affecationService
        .reassign(this.actiondata, this.id_Action)
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    }
  }
  TeamId: any;

  designation: any;
  id_message: any;
  premission: any;
  changeaction(event: any) {
    this.userslist = [];
    this.selectetuser = null;
    if (event == undefined) {
      this.userslist = [];
    }

    if (event.id == '1') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SAVE';
    } else if (event.id == '2') {
      this.getUser(this.TeamId, this.premission + 'S');
      this.acions_t = 'SUBMIT';
    }
  }

  color_active = '#77838e';
  status_sc: boolean = false;
  RoleTeammanager: boolean = false;
  tradingcompany: boolean = false;

  transactiontype = 'Save';
  id_Action: any;
  action: any;
  useraction: any;
  actiondata: any;

  reassign(action: any) {
    this.transactiontype = 'Update';
    this.action = action;
    this.actiondata = action;
    this.id_Action = action.id;
    this.useraction = action.affectedToUser;
    this.selectetuser = this.useraction.userName;
    this.Id_user = this.selectetuser.id;
    this.selectetassign = this.action.code;
    this.designation = this.action.designation;
    this.status_sc = false;
  }
}
