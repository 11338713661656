import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

import { UserService } from 'src/Services/User/user.service';

import Langue from './../../../../assets/langue.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  langue_list: any[] = Langue;
  languef: any;
  langue = 'AR';
  direction = 'rtl';
  loginForm: FormGroup;
  id_red: any;
  sr: any;
  submitted = false;
  id_langue: any;
  generadtoken: any;
  langue_nom: any;
  messagelogin: any;
  langueh: any;

  token: any;
  sc: any;
  redirection_user: any;
  constructor(
    private formBuilder: FormBuilder,
    private loginService: UserService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.redirection_user = environment.urlGestionUser;

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'EN';
    }

    if (this.langue == 'AR') {
      this.langue_nom = 'Arabic';
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].login;
      this.langueh = this.langue_list[0]['AR'].header;
    }

    if (this.langue == 'EN') {
      this.langue_nom = 'English';
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].login;
      this.langueh = this.langue_list[0]['EN'].header;
    }

    if (this.langue == 'FR') {
      this.langue_nom = 'Fançais';
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].login;
      this.langueh = this.langue_list[0]['FR'].header;
    }

    this.messagelogin = this.languef.message;

    this.id_red = this.route.snapshot.params['id'];
    this.sr = this.route.snapshot.params['id1'];

    this.sc = this.route.snapshot.params['sc'];
    this.token = this.route.snapshot.params['token'];

    /* 
        if (this.id_red != undefined) {
          this.getsession();
    
          setTimeout(() => {
            this.redirection();
          }, 700);
        } else {
          if (this.loginService.IsUserLogged()) {
            if (this.loginService.Validtoken()) {
              let token = this.loginService.decrypte(localStorage.getItem('token'));
              this.Go_E_services(token);
            }
          }
        } */

    if (this.sc != undefined) {
      this.redirection_btl = true;
      let token = this.loginService.crypte(this.token);
      localStorage.setItem('token', token + '');
      localStorage.setItem('source', 'ES');
      localStorage.setItem('id_user', '0');
      this.Go_E_services_BTL(this.token);
    }
  }

  redirection_btl: boolean = false;

  changelangue(langue: any) {
    localStorage.setItem('langue', langue);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }
  routeSub: Subscription;
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      userName: [null, Validators.required],
      password: [null, [Validators.required]],
    });
  }

  get loginControls() {
    return this.loginForm.controls;
  }

  roles: any = [];
  login(content4: any) {
    this.spinner.show();
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let data = {
      userName: this.loginForm.value.userName,
      password: this.loginForm.value.password,
    };

    this.loginService.login(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        let token = this.loginService.crypte(res.token);
        localStorage.setItem('token', token + '');
        localStorage.setItem('source', 'ES');
        localStorage.setItem('id_user', res.id);
        this.Go_E_services(res.token);
      },
      (error) => {
        this.spinner.hide();

        this.modalService.open(content4);
      }
    );
  }

  Message: any = '';

  navigation(roles: any) {
    for (let role of roles) {
      switch (role) {
        case 'Agent':
          localStorage.setItem('activelogistic', 'active');
          this.router.navigateByUrl('Logistic/liste');
          break;
        case 'Port':
          localStorage.setItem('activelogistic', 'active');
          this.router.navigateByUrl('dashboard/logistic');

          break;

        case 'DOUANES':
          localStorage.setItem('activecustom', 'active');
          this.router.navigateByUrl('dashboard/customs');

          break;

        case 'FDCC':
          this.router.navigateByUrl('dashboard/fdcc');

          break;

        case 'Trading Company':
          this.redurection_t();

          break;

        case 'Banque':
          this.router.navigateByUrl('dashboard/trade');

          break;

        case 'Exporter':
          this.router.navigateByUrl('billofladingshipper/liste');

          break;

        case 'COTUNACE':
          this.router.navigateByUrl('dashboard/dashboard_dhamenexports');

          break;

        case 'Team manager':

          this.router.navigateByUrl('affectation/organisation');
          localStorage.setItem('activeassign', 'active');
          break;
        case 'Chamber_of_Commerce':
          this.router.navigateByUrl('dashboard/trade');
          break;
        default:
          window.location.href = 'profil';
      }
    }
  }

  token_seesion: any;

  Sessionlist: any[] = [];
  getsession() {
    this.loginService.GetSession(this.id_red).subscribe((res: any) => {
      this.token_seesion = res;
      let token = this.loginService.crypte(this.token_seesion['token']);
      let user = this.loginService.getDecodedAccessToken(
        this.token_seesion['token']
      );

      this.loginForm.controls['userName'].setValue(user['sub']);
      this.loginForm.controls['password'].setValue('00000000');
      localStorage.setItem('token', token + '');
      localStorage.setItem('source', 'ES');
      localStorage.setItem('id_user', this.id_red);
    });
  }

  redirection() {
    if (this.loginService.Validtoken() == true) {
      switch (this.sr) {
        case 'Ship_arrival':
          this.router.navigateByUrl('Logistic/liste');
          break;
        case 'Ship_departure':
          this.router.navigateByUrl('Logistic/liste');

          break;
        case 'Ship_docking':
          this.router.navigateByUrl('Logistic/liste');

          break;
        case 'Cargo_manifest':
          this.router.navigateByUrl('Logistic/liste');

          break;

        case 'Customs_declaration':
          this.router.navigateByUrl('Customs/new');

          break;
      }
    }
  }

  hide = true;
  get passwordInput() {
    return this.loginForm.get('password') + '';
  }
  password: any;

  redurection_t() {
    let trade =
      this.GetCreditLetter() ||
      this.GetCertificatOrigin() ||
      this.GetShippingGarante();
    let customs = this.GetCustomsDeclaration() || this.GetTechnicalControl();

    if (trade && customs) {
      localStorage.setItem('activedash', 'active');
      this.router.navigateByUrl('dashboard_global');
    } else if (trade == false && customs) {
      localStorage.setItem('activecustoms', 'active');
      this.router.navigateByUrl('Customs/liste');
    } else if (trade && customs == false) {
      localStorage.setItem('activetrade', 'active');
      this.router.navigateByUrl('Trade/liste');
    } else {
      this.router.navigateByUrl('Error/notfound');
    }
  }

  GetTechnicalControl() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'TM') {
        localStorage.setItem('premission_TM', flux_r[1]);
        reponse = true;
      }
    }
    return reponse;
  }

  ValCD: any = [];
  ValSA: any = [];
  ValSDP: any = [];
  ValSD: any = [];
  ValCM: any = [];
  ValTO: any = [];

  description: any;

  GetCustomsDeclaration() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CD') {
        localStorage.setItem('premission_CD', flux_r[1]);
        reponse = true;
      }
    }
    return reponse;
  }

  ValLC: any = [];
  ValCO: any = [];
  ValSG: any = [];

  GetCreditLetter() {
    let reponse = false;
    this.ValLC = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValLC = this.description.split(',');
      } else {
        this.ValLC[0] = this.description;
      }
    }

    for (let flux of this.ValLC) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'LC') {
        localStorage.setItem('premission_LC', flux_r[1]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetCertificatOrigin() {
    let reponse = false;
    this.ValCO = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCO = this.description.split(',');
      } else {
        this.ValCO[0] = this.description;
      }
    }

    for (let flux of this.ValCO) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CO') {
        localStorage.setItem('premission_CO', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetShippingGarante() {
    let reponse = false;
    this.ValSG = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValSG = this.description.split(',');
      } else {
        this.ValSG[0] = this.description;
      }
    }

    for (let flux of this.ValSG) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SG') {
        localStorage.setItem('premission_CO', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  Go_E_services(token_1: any) {
    let token_f = this.loginService.getDecodedAccessToken(token_1);
    localStorage.setItem('idorg', token_f['idOrg']);
    this.generadtoken = token_f;
    if (this.generadtoken != null) {
      this.description = this.generadtoken['Description'];
    }
    let roles = this.loginService.Getroles();
    setTimeout(() => {
      localStorage.setItem('langue', this.langue);
      this.navigation(roles);
    }, 300);
  }

  Go_E_services_BTL(token_1: any) {
    let token_f = this.loginService.getDecodedAccessToken(token_1);
    localStorage.setItem('idorg', token_f['idOrg']);
    this.generadtoken = token_f;
    if (this.generadtoken != null) {
      this.description = this.generadtoken['Description'];
    }
    setTimeout(() => {
      localStorage.setItem('langue', this.langue);
      if (this.sc == 'FDE') {
        this.router.navigateByUrl('Customs/liste');
        localStorage.setItem('redirecturFDE', '2');
      } else if (this.sc == 'CX5') {
        this.router.navigateByUrl('Export/liste');
        localStorage.setItem('redirecturexport', '4');

      }
      else {
        this.router.navigateByUrl('Trade/liste');

      }
    }, 300);
  }
}
